import classNames from 'classnames';
import BasicTable from '../../../../components/BasicTable';
import { ReactComponent as TickArrow } from '../../../../images/icons/tick-rounded-icon.svg';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { MathUtils } from '../../../../utils/math.utils';
import { valueToBigNumber } from '@aave/math-utils';
import LiquidityMiningCard from '../../../../components/liquidityMining/LiquidityMiningCard';
import { BN_ONE } from '../../../../helpers/leverage';
import { PoolUtils } from '../../../../utils';
import {
  ComputedReserveData,
  useDynamicPoolDataContext,
} from '../../../../libs/pool-data-provider';
import { useEffect, useState } from 'react';
import { usePoolsInfoData } from '../../../../store';
import BigNumber from 'bignumber.js';
import { EstimateLoopingAndAprs } from '../../../../utils/pool.utils';

interface ToolRowProps {
  handleClick: (symbol: unknown) => void;
  isActive: boolean;
  isDisabled: boolean;
  symbol: string;
  amount?: string;
  apy: string;
  price: string;
  available?: boolean;
  isDeposit?: boolean;
}

export function ToolRow({
  handleClick,
  isActive,
  isDisabled,
  symbol,
  amount,
  apy,
  price,
  available,
  isDeposit,
}: ToolRowProps) {
  const { poolsInfoData } = usePoolsInfoData();
  const { reserves } = useDynamicPoolDataContext();
  const [loopData, setLoopData] = useState<EstimateLoopingAndAprs>();
  const [currentReserve, setCurrentReserve] = useState<ComputedReserveData>();

  useEffect(() => {
    const reserve = reserves.find((reserve) => reserve.symbol === symbol);

    if (reserve && poolsInfoData.length) {
      const maxLeverage = BN_ONE.div(
        BN_ONE.minus(valueToBigNumber(reserve.baseLTVasCollateral))
      ).decimalPlaces(2, BigNumber.ROUND_FLOOR);

      const loopData = PoolUtils.getPoolLooping({
        reserve,
        poolsInfoData,
        amount: valueToBigNumber(1),
        maxLeverage,
      });

      setLoopData(loopData);
      setCurrentReserve(reserve);
    }
  }, [poolsInfoData, reserves]);
  return (
    <BasicTable.Row
      onClick={() => handleClick(symbol)}
      className={classNames(
        'tools__table-row',
        isActive && 'tools__table-row--active',
        isDisabled && 'tools__table-row--disabled'
      )}
    >
      <TickArrow className="tools__table-arrow" />
      <BasicTable.Item className="tools__table-td">
        <BasicToken className="" tokenSymbol={symbol}>
          {(asset) => (
            <>
              <BasicToken.Image size="medium" className="" icon={asset.icon} alt={asset.name} />
              <span>{asset.symbol}</span>
            </>
          )}
        </BasicToken>
      </BasicTable.Item>
      {amount ? (
        <BasicTable.Item
          className={classNames('tools__table-td', available && 'tools__table-item--available')}
        >
          {MathUtils.formatNumber(amount, 2) !== '0' ? MathUtils.toLocaleNumber(amount, 2) : '-'}
          <span className="tools__table-td-sub">
            $ {MathUtils.toLocaleNumber(valueToBigNumber(amount).times(price), 2)}
          </span>
        </BasicTable.Item>
      ) : null}
      <BasicTable.Item className="tools__table-td">
        {loopData && currentReserve ? (
          <LiquidityMiningCard
            className="MarketTable__card"
            value={apy}
            thirtyDaysValue={currentReserve.avg30DaysLiquidityRate}
            liquidityMiningValue={
              isDeposit
                ? loopData.rewardsDepositApr.toString()
                : loopData.rewardsBorrowApr.toString()
            }
            symbol={currentReserve.symbol}
            type="deposit"
          />
        ) : null}
      </BasicTable.Item>
    </BasicTable.Row>
  );
}
