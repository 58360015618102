import { valueToBigNumber } from '@aave/math-utils';
import { ComputedReserveData } from '../../libs/pool-data-provider';
import { PoolUtils, Web3Utils } from '../../utils';
import { ReserveWithBalance } from './hooks/useWalletTokens';
import { ERROR_OPTIONS, LEVERAGE_SLIPPAGE, PREMIUM_FEE } from './tools.constants';
import BigNumber from 'bignumber.js';
import { BN_ONE } from '../../helpers/leverage';
import { BorrowAPYType, ErrorMessageType } from './tools.types';
import { StorePoolInfoModel, ToolsSelectedItemType } from '../../store';

export class ToolsHelper {
  public static filterZeroAmount(
    reserves: ComputedReserveData[],
    walletData: Record<string, string>
  ): ReserveWithBalance[] {
    return Object.entries(walletData)
      .filter(([address, amount]) => {
        const reserve = reserves.find((reserve) => reserve.underlyingAsset === address);

        if (reserve) {
          const formatedAmount = Web3Utils.formatValue(amount, reserve.decimals);

          return Number(formatedAmount) > 0;
        } else {
          return false;
        }
      })
      .map(([address, amount]) => {
        const reserve = reserves.find((reserve) => reserve.underlyingAsset === address);

        if (reserve) {
          return {
            balance: Web3Utils.formatValue(amount, reserve.decimals),
            ...reserve,
          };
        } else {
          throw Error('reserve undefined');
        }
      });
  }

  public static getFeeSum() {
    return LEVERAGE_SLIPPAGE + PREMIUM_FEE;
  }

  public static calculateMaxLeverage(ltv: string, decimals: number): BigNumber {
    return BN_ONE.div(
      BN_ONE.minus(valueToBigNumber(ltv).minus(ToolsHelper.getFeeSum()))
    ).decimalPlaces(decimals, BigNumber.ROUND_FLOOR);
  }

  public static getReservesWithBalance({
    reserves,
    positions,
    isBorrow,
  }: {
    reserves: ComputedReserveData[];
    positions: any[];
    isBorrow?: boolean;
  }) {
    if (positions.length && reserves.length) {
      return positions.map((position: any) => {
        const reserve = reserves.find(
          (reserve) =>
            reserve.aTokenAddress.toLowerCase() === position.reserve.aTokenAddress.toLowerCase()
        );

        return {
          ...reserve,
          balance: !isBorrow ? position.underlyingBalance : position.currentBorrows,
        };
      }) as ReserveWithBalance[];
    } else {
      return [];
    }
  }

  public static getErrorData(message: string): ErrorMessageType | null {
    let result: ErrorMessageType | null = null;

    for (let error of ERROR_OPTIONS) {
      if (message.includes(error.name)) {
        result = error;
      }
    }

    return result;
  }

  public static calculateAvailableBorrowFromWallet(
    selectedA: ReserveWithBalance | null,
    reserve: ToolsSelectedItemType
  ): BigNumber {
    if (selectedA) {
      const walletBalanceUsd = valueToBigNumber(selectedA.balance).times(
        selectedA.priceInMarketReferenceCurrency
      );

      return walletBalanceUsd.div(reserve.priceInMarketReferenceCurrency);
    }

    return valueToBigNumber(0);
  }

  public static getBorrowAPY({
    selectedA,
    selectedB,
    amountA,
    leverage,
    shortPosition,
    poolsInfoData,
  }: {
    selectedA: ToolsSelectedItemType;
    selectedB: ToolsSelectedItemType;
    amountA: BigNumber;
    shortPosition: BigNumber;
    leverage: number;
    poolsInfoData: StorePoolInfoModel[];
  }): BorrowAPYType {
    const longPosition = amountA.times(leverage);
    const longPositionUsd = longPosition.times(selectedA.priceInMarketReferenceCurrency);
    const shortPositionUsd = shortPosition.times(selectedB.priceInMarketReferenceCurrency);
    const leverageSelectedA = BN_ONE.div(
      BN_ONE.minus(valueToBigNumber(selectedA.baseLTVasCollateral))
    ).decimalPlaces(2, BigNumber.ROUND_FLOOR);
    const leverageSelectedB = BN_ONE.div(
      BN_ONE.minus(valueToBigNumber(selectedB.baseLTVasCollateral))
    ).decimalPlaces(2, BigNumber.ROUND_FLOOR);

    const loopDataSelectedA = PoolUtils.getPoolLooping({
      reserve: selectedA,
      poolsInfoData,
      amount: valueToBigNumber(1),
      maxLeverage: leverageSelectedA,
    });

    const loopDataSelectedB = PoolUtils.getPoolLooping({
      reserve: selectedB,
      poolsInfoData,
      amount: valueToBigNumber(1),
      maxLeverage: leverageSelectedB,
    });

    const rewardDepositEmmissionsUSD = longPositionUsd.times(loopDataSelectedA.rewardsDepositApr);
    const rewardBorrowEmmissionsUSD = shortPositionUsd.times(loopDataSelectedB.rewardsBorrowApr);
    const totalEmmisionsUSD = rewardDepositEmmissionsUSD.plus(rewardBorrowEmmissionsUSD).div(100);

    const depositAPYUSD = longPositionUsd.times(selectedA.supplyAPY).div(100);
    const borrowAPYUSD = shortPositionUsd.times(selectedB.variableBorrowAPY).div(100);

    const totalRevenueUsd = totalEmmisionsUSD.plus(depositAPYUSD).minus(borrowAPYUSD);

    const totalAPR = totalRevenueUsd.div(amountA).times(100).decimalPlaces(2).toNumber();
    const depositAPY = depositAPYUSD.div(amountA).times(100).decimalPlaces(2).toNumber();
    const borrowAPY = borrowAPYUSD.div(amountA).times(100).decimalPlaces(2).toNumber();
    const rewardAPR = totalEmmisionsUSD.div(amountA).times(100).decimalPlaces(2).toNumber();

    return {
      borrowAPY,
      depositAPY,
      rewardAPR,
      totalAPR,
    };
  }
}
