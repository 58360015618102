import { valueToBigNumber } from '@aave/protocol-js';
import BigNumber from 'bignumber.js';

type NumberType = number | BigNumber | string | undefined;

export class MathUtils {
  public static formatNumber(num?: NumberType, decimals?: number): string {
    if (num === undefined || isNaN(Number(num)) || num.toString() === 'Infinity') return '0';

    const number = new BigNumber(num);

    if (number.isGreaterThanOrEqualTo(1000000000)) {
      return number.dividedBy(1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (number.isGreaterThanOrEqualTo(1000000)) {
      return number.dividedBy(1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (number.isGreaterThanOrEqualTo(1000)) {
      return number.dividedBy(1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return decimals ? MathUtils.truncateNumber(number, decimals).toString() : number.toString();
  }

  public static truncateNumber(number: NumberType, decimals: number): string {
    if (
      number === undefined ||
      isNaN(Number(number)) ||
      number.toString() === 'Infinity' ||
      Number(number) < 0
    ) {
      return '0';
    }

    const minValue = 10 ** -(decimals || 5);

    if (valueToBigNumber(number).lt(minValue)) {
      const relevantNumber = MathUtils.getRelevantNumber(number.toString());

      return Number(relevantNumber) > 0 ? relevantNumber : '0';
    }

    const bigNumberValue = new BigNumber(number);

    const factor = new BigNumber(10).exponentiatedBy(decimals);
    const result = bigNumberValue.times(factor).integerValue(BigNumber.ROUND_DOWN).div(factor);

    if (result.gt(0)) {
      return result.toFixed();
    } else {
      return '0';
    }
  }

  public static toLocaleNumber(number: NumberType, decimals: number): string {
    if (
      number === undefined ||
      isNaN(Number(number)) ||
      number.toString() === 'Infinity' ||
      Number(number) < 0
    ) {
      return '0';
    }

    const minValue = 10 ** -(decimals || 5);

    if (valueToBigNumber(number).lt(minValue)) {
      const relevantNumber = MathUtils.getRelevantNumber(number.toString());

      return Number(relevantNumber) > 0 ? relevantNumber : '0';
    }

    const bigNumberValue = new BigNumber(number);

    const factor = new BigNumber(10).exponentiatedBy(decimals);

    const result = bigNumberValue
      .times(factor)
      .integerValue(BigNumber.ROUND_DOWN)
      .dividedBy(factor);

    if (result.gt(0)) {
      return result.toFormat();
    } else {
      return '0';
    }
  }

  public static getSumOfArray(array: BigNumber[]) {
    return array.reduce((accumulator, number) => accumulator.plus(number), valueToBigNumber(0));
  }

  public static getRelevantNumber(number: string): string {
    const formatedNumber = valueToBigNumber(number).toFixed();
    const [integerPart, decimalPart] = formatedNumber.split('.');

    if (!decimalPart || decimalPart === '0') {
      return formatedNumber.toString();
    }

    const significantMatch = decimalPart.match(/[1-9]/);

    if (significantMatch) {
      const significantIndex = decimalPart.indexOf(significantMatch[0]);
      const trimmedDecimalPart = decimalPart.slice(0, significantIndex + 1);
      const trimmedNumberString = integerPart + '.' + trimmedDecimalPart;

      return trimmedNumberString;
    }

    return formatedNumber;
  }
}
