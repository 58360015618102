import { BigNumber, providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { SwapLoop } from './typechain/contracts';
import { SwapLoop__factory } from './typechain/factories';
import { Address } from 'viem';
import { Web3Utils } from '../../utils';
import {
  BaseDebtToken,
  BaseDebtTokenInterface,
} from '@aave/contract-helpers/dist/esm/baseDebtToken-contract';
import { LendingPoolService } from '../aave-protocol-js/LendingPool';
import { LP_CONTRACT_ADDRESS } from '../aave-protocol-js/LendingPool/typechain/factories/LendingPool__factory';
import { ReserveWithBalance } from '../../modules/tools/hooks';
import { ComputedReserveData } from '../pool-data-provider';
import { LeverageTabs } from '../../modules/tools/Leverage';
import { DEFAULT_APPROVE_AMOUNT } from '@aave/protocol-js';
import { SLIPPAGE_OFFSET } from '../FlashLoanBorrow';

export class SwapLoopContract extends BaseService<SwapLoop> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;
  readonly baseDebtToken: BaseDebtTokenInterface;

  constructor(provider: providers.Provider, swapLoopContractAddress: string) {
    super(provider, SwapLoop__factory);

    this.contractAddress = swapLoopContractAddress;
    this.erc20Service = new ERC20Service(provider);
    this.baseDebtToken = new BaseDebtToken(provider, this.erc20Service);
  }

  public async flashBorrow({
    collateral,
    collateralAmount,
    borrow,
    leverage,
    user,
    borrowAmount,
    leverageFrom,
    slippage,
  }: {
    user: Address;
    collateral: ReserveWithBalance;
    collateralAmount: string;
    borrow: ComputedReserveData;
    leverage: number;
    borrowAmount: string;
    leverageFrom: LeverageTabs;
    slippage: number;
  }): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve } = this.erc20Service;

    const txs: EthereumTransactionTypeExtended[] = [];
    const swapLoopContract: SwapLoop = this.getContractInstance(this.contractAddress);

    const collateralAmountWei = Web3Utils.toWei(collateralAmount, collateral.decimals);
    const formatedLeverage = Number(leverage) * 10;
    const formatedSlippage = slippage * 100 + SLIPPAGE_OFFSET;

    if (leverageFrom === LeverageTabs.Wallet) {
      const approved = await isApproved({
        token: collateral.underlyingAsset,
        user: user as string,
        spender: this.contractAddress,
        amount: collateralAmount,
      });

      if (!approved) {
        const approveTx = approve({
          user: user as string,
          token: collateral.underlyingAsset,
          spender: this.contractAddress,
          amount: collateralAmountWei.toString(),
        });
        txs.push(approveTx);
      }
    }

    const delegationApproved = await this.baseDebtToken.isDelegationApproved({
      debtTokenAddress: borrow.variableDebtTokenAddress,
      allowanceGiver: user as string,
      allowanceReceiver: this.contractAddress,
      amount: borrowAmount,
    });

    if (!delegationApproved) {
      const approveTx = this.baseDebtToken.approveDelegation({
        user: user as string,
        delegatee: this.contractAddress,
        debtTokenAddress: borrow.variableDebtTokenAddress,
        amount: DEFAULT_APPROVE_AMOUNT,
      });
      txs.push(approveTx);
    }

    console.log('call flashBorrow: ', [
      collateral.underlyingAsset,
      collateralAmountWei,
      borrow.underlyingAsset,
      formatedLeverage.toString(),
      formatedSlippage.toString(),
    ]);

    const props: [string, BigNumber, string, string, string] = [
      collateral.underlyingAsset,
      collateralAmountWei,
      borrow.underlyingAsset,
      formatedLeverage.toString(),
      formatedSlippage.toString(),
    ];

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        leverageFrom === LeverageTabs.Wallet
          ? swapLoopContract.populateTransaction.borrowFromWallet(...props)
          : swapLoopContract.populateTransaction.borrowFromDeposit(...props),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation([], txCallback),
    });

    return txs;
  }

  public async flashRepay({
    borrow,
    borrowAmount,
    repayWith,
    user,
    slippage,
  }: {
    borrow: ReserveWithBalance;
    borrowAmount: string;
    repayWith: ReserveWithBalance;
    user: Address;
    slippage: number;
  }): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];
    const swapLoopContract: SwapLoop = this.getContractInstance(this.contractAddress);
    const borrowAmountWei = Web3Utils.toWei(borrowAmount, borrow.decimals);
    const formatedSlippage = slippage * 100 + SLIPPAGE_OFFSET;

    const LPContract = new LendingPoolService(this.provider, LP_CONTRACT_ADDRESS);

    const isApproved = await LPContract.isDelegateApproved({ user, address: this.contractAddress });

    if (!isApproved) {
      const delegationTx = await LPContract.delegateWithdraw({
        user,
        address: this.contractAddress,
      });

      txs.push(delegationTx);
    }

    console.log(
      borrow.underlyingAsset,
      borrowAmountWei,
      repayWith.underlyingAsset,
      formatedSlippage.toString()
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        swapLoopContract.populateTransaction.repayFromDeposit(
          borrow.underlyingAsset,
          borrowAmountWei,
          repayWith.underlyingAsset,
          formatedSlippage.toString()
        ),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation(txs, txCallback),
    });

    return txs;
  }

  public async getBorrowAmount({
    collateral,
    collateralAmount,
    borrow,
    leverage,
  }: {
    collateral: ReserveWithBalance;
    collateralAmount: string;
    borrow: ComputedReserveData;
    leverage: number;
  }): Promise<string> {
    const formatedLeverage = Number(leverage) * 10;
    const collateralAmountWei = Web3Utils.toWei(collateralAmount, collateral.decimals);

    const contract: SwapLoop = this.getContractInstance(this.contractAddress);

    const borrowAmount = await contract.borrowBorrowAmount(
      collateral.underlyingAsset,
      collateralAmountWei,
      borrow.underlyingAsset,
      formatedLeverage
    );

    return Web3Utils.formatValue(borrowAmount, borrow.decimals);
  }

  public async getRepayAmount({
    repay,
    repayAmount,
    collateral,
  }: {
    repay: ReserveWithBalance;
    repayAmount: string;
    collateral: ReserveWithBalance;
  }): Promise<string> {
    const repayAmountWei = Web3Utils.toWei(repayAmount, repay.decimals);

    const contract: SwapLoop = this.getContractInstance(this.contractAddress);

    const collateralAmount = await contract.callStatic.repayCollateralAmount(
      repay.underlyingAsset,
      repayAmountWei,
      collateral.underlyingAsset
    );

    return Web3Utils.formatValue(collateralAmount, collateral.decimals);
  }
}
