import staticStyles from './style';
import BasicToken from '../../../../components/BasicToken/BasicToken';
import { ReactNode, useMemo } from 'react';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import { getAssetInfo } from '../../../../libs/aave-ui-kit';
import { ToolsSelectedItemType, usePoolsInfoData, useToolsStore } from '../../../../store';
import { valueToBigNumber } from '@aave/math-utils';
import { PoolUtils } from '../../../../utils';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { MathUtils } from '../../../../utils/math.utils';
import HealthFactor from '../../../../components/HealthFactor';
import { TokensSymbolEnum } from '../../../../shared';
import BigNumber from 'bignumber.js';
import { BorrowAPYType } from '../../tools.types';
import { ToolsHelper } from '../../tools.helper';

export function ToolsInfo({ children }: { children: ReactNode }) {
  return (
    <>
      <div className="tools-info">{children}</div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

ToolsInfo.Position = ({
  item,
  title,
  amount,
}: {
  item: ToolsSelectedItemType | null;
  amount: BigNumber | null;
  title: string;
}) => {
  const tokenAmount = amount !== null ? amount : '0';

  return (
    <BasicBox variant="secondary" className="tools__inner-box tools-info__position">
      <p className="tools-info__text">{title}</p>

      <div className="tools-info__token">
        {item ? (
          <BasicToken tokenSymbol={getAssetInfo(item.symbol).symbol}>
            {(asset) => (
              <>
                <BasicToken.Image
                  icon={asset.icon}
                  size="medium"
                  className="tools-info__token-img"
                />
                <span className="tools-info__token-name">{asset.symbol}</span>
              </>
            )}
          </BasicToken>
        ) : (
          '-'
        )}
        <div className="tools-info__token-right">
          <p className="tools-info__token-balance">{MathUtils.toLocaleNumber(tokenAmount, 2)}</p>
          <span className="tools-info__token-usd">
            ${' '}
            {item
              ? MathUtils.toLocaleNumber(
                  valueToBigNumber(tokenAmount).times(item.priceInMarketReferenceCurrency),
                  2
                )
              : '0'}
          </span>
        </div>
      </div>
    </BasicBox>
  );
};

ToolsInfo.Group = ({ children }: { children: ReactNode }) => {
  return <div className="tools-info__group">{children}</div>;
};

export function ToolsInfoList() {
  const amountA = useToolsStore.use.amountA();
  const amountB = useToolsStore.use.amountB();
  const selectedA = useToolsStore.use.selectedA();
  const selectedB = useToolsStore.use.selectedB();

  const { poolsInfoData } = usePoolsInfoData();
  const { user } = useDynamicPoolDataContext();
  const leverage = useToolsStore.use.leverage();

  const { loopingData, borrowsAPY } = useMemo(
    () => ({
      loopingData:
        user && poolsInfoData.length && selectedB
          ? PoolUtils.getPoolLooping({
              reserve: selectedB,
              poolsInfoData,
              amount: valueToBigNumber(amountB),
              maxLeverage: valueToBigNumber(leverage),
              userSummary: user,
            })
          : null,
      borrowsAPY:
        selectedA && selectedB && amountA && amountB
          ? ToolsHelper.getBorrowAPY({
              selectedA,
              selectedB,
              leverage,
              poolsInfoData,
              amountA: valueToBigNumber(amountA),
              shortPosition: valueToBigNumber(amountB),
            })
          : null,
    }),
    [amountB]
  );

  return (
    <BasicBox variant="secondary" className="tools__inner-box tools-info__list">
      {loopingData && borrowsAPY ? (
        <>
          <div className="tools-info__list-item">
            <p>{TokensSymbolEnum.REWARD} emissions APR</p> <span>{borrowsAPY.rewardAPR}%</span>
          </div>
          <br />
          <div className="tools-info__list-item">
            <p>Deposit Native APR</p> <span>{borrowsAPY.depositAPY}%</span>
          </div>
          <div className="tools-info__list-item">
            <p>Borrow Native Interest</p> <span>{borrowsAPY.borrowAPY}%</span>
          </div>
          <div className="tools-info__list-item">
            <p>Total APR</p> <span>{borrowsAPY.totalAPR}%</span>
          </div>
          <br />
          <div className="tools-info__list-item">
            <p>Health Factor:</p>{' '}
            <HealthFactor value={MathUtils.formatNumber(loopingData.healthFactor, 2)} />
          </div>
        </>
      ) : (
        <>
          <div className="tools-info__list-item">
            <p>{TokensSymbolEnum.REWARD} emissions APR</p> <span>-</span>
          </div>
          <br />
          <div className="tools-info__list-item">
            <p>Deposit Native APR</p> <span>-</span>
          </div>
          <div className="tools-info__list-item">
            <p>Borrow Native Interest</p> <span>-</span>
          </div>
          <div className="tools-info__list-item">
            <p>Health Factor:</p> <span>-</span>
          </div>
          <br />
          <div className="tools-info__list-item">
            <p>Total APR:</p> <span>-</span>
          </div>
        </>
      )}
    </BasicBox>
  );
}
