import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .zap-modal {
    width: 445px;

    @include respond-to(xs) {
      width: 100%;
    }

    &__header {
      position: relative;
    }

    &__back {
      position: absolute;
      left: 0;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 12px;
      top: 8px;
      cursor: pointer;

      svg {
        transition: 0.2s;
      }

      &:hover {
        svg {
          transform: translateX(-4px);
        }
      }
    }

    &__emission {
      padding: 16px;
      color: rgba(0, 0, 0, 0.4);
      margin-top: 24px;

      @include respond-to(xs) {
        margin-top: 18px;
        padding: 16px 12px;
      }
    }

    &__emission-title {
      font-size: 16px;
      font-weight: 600;
      color: #000;

      @include respond-to(xs) {
        font-size: 14px;
      }
    }

    &__emission-text {
      margin: 12px 0;

      @include respond-to(xs) {
        font-size: 12px;
      }
    }

    &__range {
      margin: 12px 0 0;
    }

    &__range-token {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__range-icon {
      width: 16px;
      height: 16px;
    }

    &__range-text {
      font-size: 14px;
    }

    &__eligibility {
      margin-top: 12px;
      width: 100%;
    }

    &__fields {
      margin-top: 24px;
    }

    &__field {
      margin: 12px 0 0;
    }

    &__field-select {
      margin: -8px -8px -8px 0;
    }

    &__btn-continue {
      margin-top: 24px;

      @include respond-to(xs) {
        margin-top: 12px;
      }
    }

    &__list {
      padding: 16px;
      margin-top: 24px;
    }

    &__list-item {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      padding: 6px 0;
    }

    & .zap-modal__tabs {
      display: inline-flex;
      margin-top: 8px;
      padding: 0;
    }

    & &__slippage {
      display: flex;
      justify-content: flex-end;
      margin-top: -12px;
      margin-bottom: -6px;
    }
  }
`;

export default staticStyles;
