import { providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { FlashLoanRepay as RepayInterface } from './typechain/contracts';
import { FlashLoan__factory } from './typechain/factories';
import { Address } from 'viem';
import { Web3Utils } from '../../utils';
import {
  BaseDebtToken,
  BaseDebtTokenInterface,
} from '@aave/contract-helpers/dist/esm/baseDebtToken-contract';
import { LendingPoolService } from '../aave-protocol-js/LendingPool';
import { LP_CONTRACT_ADDRESS } from '../aave-protocol-js/LendingPool/typechain/factories/LendingPool__factory';
import { ReserveWithBalance } from '../../modules/tools/hooks';
import { iota } from '../../ui-config/markets/tokensConfig';
import { valueToBigNumber } from '@aave/math-utils';

export const FLASHLOAN_REPAY_ADDRESS = iota.FlashLoanRepay;

export const SLIPPAGE_OFFSET = 10000;

export class FlashLoanRepay extends BaseService<RepayInterface> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;
  readonly baseDebtToken: BaseDebtTokenInterface;

  constructor(provider: providers.Provider, flashLoanContractAddress: string) {
    super(provider, FlashLoan__factory);

    this.contractAddress = flashLoanContractAddress;
    this.erc20Service = new ERC20Service(provider);
    this.baseDebtToken = new BaseDebtToken(provider, this.erc20Service);
  }

  public async flashRepay({
    borrow,
    borrowAmount,
    repayWith,
    user,
    slippage,
  }: {
    borrow: ReserveWithBalance;
    borrowAmount: string;
    repayWith: ReserveWithBalance;
    user: Address;
    slippage: number;
  }): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];
    const flashLoanContract: RepayInterface = this.getContractInstance(this.contractAddress);
    const borrowAmountWei = Web3Utils.toWei(borrowAmount, borrow.decimals);
    const formatedSlippage = slippage * 100 + SLIPPAGE_OFFSET;

    const isMaxRepay = valueToBigNumber(borrow.balance).eq(borrowAmount);

    const LPContract = new LendingPoolService(this.provider, LP_CONTRACT_ADDRESS);

    const isApproved = await LPContract.isDelegateApproved({ user, address: this.contractAddress });

    if (!isApproved) {
      const delegationTx = await LPContract.delegateWithdraw({
        user,
        address: this.contractAddress,
      });

      txs.push(delegationTx);
    }

    const args = [
      borrow.underlyingAsset,
      ...(isMaxRepay ? [] : [borrowAmountWei]),
      repayWith.underlyingAsset,
      formatedSlippage,
      true,
    ];

    console.log(args);

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        isMaxRepay
          ? (flashLoanContract.populateTransaction as any)[
              'repayFromDeposit(address,address,uint16,bool)'
            ](...args)
          : (flashLoanContract.populateTransaction as any)[
              'repayFromDeposit(address,uint256,address,uint16,bool)'
            ](...args),
      from: user,
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.STAKE_ACTION,
      gas: this.generateTxPriceEstimation(txs, txCallback),
    });

    return txs;
  }

  public async getRepayAmount({
    repay,
    repayAmount,
    collateral,
  }: {
    repay: ReserveWithBalance;
    repayAmount: string;
    collateral: ReserveWithBalance;
  }): Promise<string> {
    const repayAmountWei = Web3Utils.toWei(repayAmount, repay.decimals);

    const contract: RepayInterface = this.getContractInstance(this.contractAddress);

    const collateralAmount = await contract.callStatic.repayCollateralAmount(
      repay.underlyingAsset,
      repayAmountWei,
      collateral.underlyingAsset
    );

    return Web3Utils.formatValue(collateralAmount, collateral.decimals);
  }
}
