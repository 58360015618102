import { Address } from 'viem';
import { TokenData } from '../types/token.types';
import { TokensSymbolEnum } from '../enums';

export const REWARD_ADDRESS: Address = '0xF5755e48B6F2F06F8ea904bdA26177CB3ca06Ff0';
export const USDT_ADDRESS: Address = '0xc1b8045a6ef2934cf0f78b0dbd489969fa9be7e4';
export const aUSDT_ADDRESS: Address = '0x7fA6e7C26Fac91541306B0240f930599F6e1D041';
export const vUSDT_ADDRESS: Address = '0xa1781d4b6f21e2fc6651834c9c480d42935b8219';

export const wIOTA_ADDRESS: Address = '0x6e47f8d48a01b44df3fff35d258a10a3aedc114c';
export const aWIOTA_ADDRESS: Address = '0xdad653B668b0E10D665eAdAe0A873c69f5280c45';
export const vWIOTA_ADDRESS: Address = '0xf97224a18f6c44a245c1443320ce84f6bdd437d4';

export const WETH_ADDRESS: Address = '0x160345fc359604fc6e70e3c5facbde5f7a9342d8';
export const aWETH_ADDRESS: Address = '0xe8d4c068c26B6fd6EFbf828d2097f1A94035159D';
export const vWETH_ADDRESS: Address = '0x4b96AFf3D69a41cCbe59B0c2DD708433e367e46c';

export const USDC_ADDRESS: Address = '0xFbDa5F676cB37624f28265A144A48B0d6e87d3b6';
export const aUSDC_ADDRESS: Address = '0x349a53f7bc66b2172939588901dfbc96854d6f28';
export const vUSDC_ADDRESS: Address = '0x3c2db6a2d4673131b7217aec451c88f18f7c5f42';

export const LP_TOKEN_ADDRESS: Address = '0x193E079575317Dd3b31F051f338A679Ba82A6018';

// Об'єкти
export const MARKET_TOKENS: TokenData[] = [
  { symbol: TokensSymbolEnum.USDT, token: USDT_ADDRESS },
  { symbol: TokensSymbolEnum.wIOTA, token: wIOTA_ADDRESS },
  { symbol: TokensSymbolEnum.WETH, token: WETH_ADDRESS },
  { symbol: TokensSymbolEnum.USDC, token: USDC_ADDRESS },
];

export const REWARD_TOKENS: TokenData[] = [
  { symbol: TokensSymbolEnum.USDT, token: aUSDT_ADDRESS },
  { symbol: TokensSymbolEnum.wIOTA, token: aWIOTA_ADDRESS },
  { symbol: TokensSymbolEnum.WETH, token: aWETH_ADDRESS },
  { symbol: TokensSymbolEnum.REWARD, token: REWARD_ADDRESS },
  { symbol: TokensSymbolEnum.USDC, token: aUSDC_ADDRESS },
];

export const VARIABLE_TOKENS: TokenData[] = [
  { symbol: TokensSymbolEnum.USDT, token: vUSDT_ADDRESS },
  { symbol: TokensSymbolEnum.wIOTA, token: vWIOTA_ADDRESS },
  { symbol: TokensSymbolEnum.WETH, token: vWETH_ADDRESS },
  { symbol: TokensSymbolEnum.USDC, token: vUSDC_ADDRESS },
];

export const NEW_TOKENS: TokenData[] = [{ symbol: TokensSymbolEnum.wIOTA, token: wIOTA_ADDRESS }];
