import { providers } from 'ethers';
import BaseService from '@aave/contract-helpers/dist/esm/commons/BaseService.js';
import {
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  tEthereumAddress,
  transactionType,
} from '@aave/contract-helpers/dist/esm/commons/types';
import { DEFAULT_APPROVE_AMOUNT, valueToWei } from '@aave/contract-helpers/dist/esm/commons/utils';
import {
  ERC20Service,
  IERC20ServiceInterface,
} from '@aave/contract-helpers/dist/esm/erc20-contract';

import { Zap } from './typechain/contracts';
import { Zap__factory } from './typechain/factories';

import { LockDurationIndex } from '../../../modules/manage/components/ManageLock/ManageLock';
import { ZapFromEnum } from '../../../components';
import { Address } from 'viem';
import { GAS_DECIMALS, LP_TOKEN_ADDRESS } from '../../../shared';
import BigNumber from 'bignumber.js';

export interface ZapProps {
  user: tEthereumAddress;
  wethAmount: string;
  rewardAmount: string;
  lockDurationIndex: LockDurationIndex;
  isWETH: boolean;
  from: ZapFromEnum;
  assetAddress: Address;
  rewardAddress: Address;
  lpAmount: BigNumber;
}

export class ZapService extends BaseService<Zap> {
  public readonly contractAddress: tEthereumAddress;

  readonly erc20Service: IERC20ServiceInterface;

  constructor(provider: providers.Provider, multiFeeDistribution: string) {
    super(provider, Zap__factory);

    this.contractAddress = multiFeeDistribution;
    this.erc20Service = new ERC20Service(provider);
  }

  public async zap({
    user,
    wethAmount,
    rewardAmount,
    isWETH,
    lockDurationIndex,
    from,
    assetAddress,
    rewardAddress,
    lpAmount,
  }: ZapProps): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];
    const ZapContract: Zap = this.getContractInstance(this.contractAddress);
    const { isApproved, approve, decimalsOf } = this.erc20Service;

    const [lpDecimals, rewardDecimals] = await Promise.all([
      decimalsOf(LP_TOKEN_ADDRESS),
      decimalsOf(rewardAddress),
    ]);

    const convertedWETHAmount: string = valueToWei(wethAmount, GAS_DECIMALS);
    const convertedRewardAmount: string = valueToWei(rewardAmount, rewardDecimals);
    const convertedLPAmount = valueToWei(lpAmount.decimalPlaces(lpDecimals).toFixed(), lpDecimals);

    if (!isWETH) {
      const isAssetApproved = await isApproved({
        token: assetAddress,
        user,
        spender: this.contractAddress,
        amount: convertedWETHAmount,
      });

      if (!isAssetApproved) {
        const approveTx: EthereumTransactionTypeExtended = approve({
          user,
          token: assetAddress,
          spender: this.contractAddress,
          amount: DEFAULT_APPROVE_AMOUNT,
        });
        txs.push(approveTx);
      }
    }

    if (from === ZapFromEnum.Wallet) {
      const isRewardApproved = await isApproved({
        token: rewardAddress,
        user,
        spender: this.contractAddress,
        amount: convertedRewardAmount,
      });

      if (!isRewardApproved) {
        const approveTx: EthereumTransactionTypeExtended = approve({
          user,
          token: rewardAddress,
          spender: this.contractAddress,
          amount: DEFAULT_APPROVE_AMOUNT,
        });
        txs.push(approveTx);
      }
    }

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: async () => {
        return from === ZapFromEnum.Wallet
          ? ZapContract.populateTransaction.zap(
              assetAddress,
              isWETH ? '0' : convertedWETHAmount,
              convertedRewardAmount,
              lockDurationIndex.toString(),
              convertedLPAmount
            )
          : ZapContract.populateTransaction.zapFromVesting(
              assetAddress,
              isWETH ? '0' : convertedWETHAmount,
              lockDurationIndex.toString(),
              convertedLPAmount
            );
      },
      from: user,
      value: isWETH ? convertedWETHAmount : undefined,
    });

    txs.push({
      tx: txCallback,
      txType: 'ZAP' as eEthereumTxType,
      gas: this.generateTxPriceEstimation([], txCallback),
    });

    return txs;
  }
}
