import { useCallback, useEffect, useMemo, useState } from 'react';
import { BasicAmountField } from '../BasicAmountField/BasicAmountField';
import { BasicModal } from '../../libs/aave-ui-kit';
import staticStyles from './style';
import DefaultButton from '../basic/DefaultButton';
import PoolTxConfirmationView from '../PoolTxConfirmationView';
import { useTxBuilderContext } from '../../libs/tx-provider';
import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { REWARD_ADDRESS, TokensSymbolEnum } from '../../shared';
import { SELECT_TOKENS } from './zap.constants';
import { Select } from '../Select/Select';
import { useTokensBalanceData } from '../../store';
import { ZapHelper } from './zap.helper';
import { MathUtils } from '../../utils/math.utils';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';
import { getAtokenInfo } from '../../helpers/get-atoken-info';
import { EligibilityProgress } from '../EmissionsRow/components/EligibilityProgress/EligibilityProgress';
import ButtonTabs from '../basic/ButtonTabs';
import { useBalanceContext } from '../../libs/wallet-balance-provider/BalanceProvider';
import useRewardTokenPrices from '../../store/tokens-price/tokens-price.hooks';
import { Address } from 'viem';
import { useProviderContext } from '../../libs/provider/WalletProvider';
import { useWeb3DataContext } from '../../libs/web3-data-provider';
import { ZapLockAprs } from './components/ZapLockAprs/ZapLockAprs';
import { useInterval, useLockData } from '../../hooks';
import { ReactComponent as BackArrow } from '../../images/icons/back-arrow.svg';
import { BasicBox } from '../BasicBox/BasicBox';
import { FACTORY_ADDRESS, FactoryApi } from '../../apis';
import { FACTORY_ABI } from '../../apis/factory/factory.abi';
import { TokenUtils } from '../../utils';
import { useEligibility } from '../EmissionsRow/components/EligibilityProgress/useEligibility';
import { ethers } from 'ethers';
import { Slippage } from '../Slippage/Slippage';
import { useDebouncedCallback } from 'use-debounce';
export interface FieldDataProps {
  value: string;
  symbol: TokensSymbolEnum;
}

enum Steps {
  amount = 'amount',
  lockApr = 'lockApr',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

interface ZapModalProps {
  onHandleClose: () => void;
  isOpen: boolean;
  onlyVestingMode?: boolean;
}

let blockingError = '';

export enum ZapFromEnum {
  Wallet = 'Wallet',
  Vesting = 'Vesting',
}

export enum FilledByEnum {
  Gas = 'Gas',
  Reward = 'Reward',
}

const TABS_OPTIONS: ZapFromEnum[] = [ZapFromEnum.Wallet, ZapFromEnum.Vesting];

export function ZapModal({ isOpen, onHandleClose, onlyVestingMode = false }: ZapModalProps) {
  const { Zap } = useTxBuilderContext();
  const { user } = useDynamicPoolDataContext();
  const { lockIndex } = useLockData();

  const { totalVesting, userTotalLockedBalance, RTTokenInfo } = useBalanceContext();
  const { userBalances, refetchTokensBalanceData } = useTokensBalanceData();
  const { prices, refetchPrices } = useRewardTokenPrices();
  const { provider } = useProviderContext();
  const { currentAccount: address } = useWeb3DataContext();
  const { isEligible } = useEligibility();

  const [slippage, setSlippage] = useState<number>(1);
  const [lpAmount, setLpAmount] = useState<BigNumber>(BigNumber(0));
  const [step, setStep] = useState<string>(Steps.amount);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [selectedTabFrom, setSelectedTabFrom] = useState<ZapFromEnum>(
    onlyVestingMode ? ZapFromEnum.Vesting : ZapFromEnum.Wallet
  );
  const [exectFrom, setExectFrom] = useState<FilledByEnum>(FilledByEnum.Gas);

  const [dataGas, setDataGas] = useState<FieldDataProps>({
    value: '',
    symbol: TokensSymbolEnum.GAS,
  });
  const [dataReward, setDataReward] = useState<FieldDataProps>({
    value: '',
    symbol: TokensSymbolEnum.REWARD,
  });

  const aTokenData = getAtokenInfo({
    address: REWARD_ADDRESS,
    symbol: TokensSymbolEnum.REWARD,
    decimals: 18,
  });

  const handleTabChange = (tab: ZapFromEnum) => {
    setSelectedTabFrom(tab);
  };

  const handleSelectChange = (selectedId: number) => {
    setSelectedItem(selectedId);
    setDataGas((state) => ({
      ...state,
      symbol: SELECT_TOKENS[selectedId],
    }));
  };

  const requiredLockedLP = useMemo(() => {
    if (user) {
      const requiredInUsd = valueToBigNumber(user.totalLiquidityUSD).times(0.05);

      return requiredInUsd.div(prices.lpTokenPriceUsd);
    } else {
      return valueToBigNumber(0);
    }
  }, [user]);

  const userGasBalance = useMemo(
    () => valueToBigNumber(ZapHelper.getBalance(userBalances, dataGas)),
    [userBalances, dataGas]
  );

  const userRewardBalance = useMemo(
    () =>
      selectedTabFrom === ZapFromEnum.Wallet
        ? valueToBigNumber(RTTokenInfo.walletBalance)
        : totalVesting,
    [selectedTabFrom, totalVesting, RTTokenInfo]
  );

  const isButtonDisabled = useMemo(() => {
    const isDisabled =
      !Number(dataGas.value) ||
      userGasBalance.lt(dataGas.value) ||
      userRewardBalance.lt(dataReward.value);

    return isDisabled && step === Steps.amount;
  }, [dataGas, step, userRewardBalance, dataReward]);

  const isOneMonthDisabled = useMemo(
    () => lockIndex === 0 && selectedTabFrom === ZapFromEnum.Vesting && step === Steps.lockApr,
    [lockIndex, selectedTabFrom, step]
  );

  const handleInputChange = useCallback(
    (value: string, exectFrom: FilledByEnum) => {
      if (selectedTabFrom === ZapFromEnum.Vesting) {
        return;
      }

      if (exectFrom === FilledByEnum.Gas) {
        setDataGas((data: FieldDataProps) => ({
          ...data,
          value: value,
        }));
      } else {
        setDataReward((data: FieldDataProps) => ({
          ...data,
          value: value,
        }));
      }

      setExectFrom(exectFrom);
      fetchData();
    },
    [selectedTabFrom]
  );

  const fetchData = useCallback(async () => {
    if (provider && isOpen) {
      refetchTokensBalanceData();
      refetchPrices();
    }
  }, [provider, address, isOpen]);

  const handleGetTransactions = useCallback(async () => {
    const basicProps = {
      user: user!.id,
      wethAmount: dataGas.value,
      rewardAmount: dataReward.value,
      lockDurationIndex: lockIndex,
      from: selectedTabFrom,
      rewardAddress: REWARD_ADDRESS,
      lpAmount: lpAmount.times(1 - slippage / 100),
    } as any;

    let dataByToken: {
      isWETH: boolean;
      assetAddress: Address;
    } = {} as any;

    const isWETH = dataGas.symbol === TokensSymbolEnum.GAS;

    dataByToken = {
      isWETH,
      assetAddress: isWETH
        ? ethers.constants.AddressZero
        : TokenUtils.getAddressBySymbol(dataGas.symbol),
    };

    return Zap.zap({
      ...basicProps,
      ...dataByToken,
    });
  }, [lpAmount, selectedTabFrom, lockIndex]);

  const handleSubmit = useCallback(() => {
    setStep(step === Steps.amount ? Steps.lockApr : Steps.confirmation);
  }, [step]);

  const debouncedFetchData = useDebouncedCallback(
    async (tokenData: FieldDataProps, from: FilledByEnum) => {
      if (!Number(tokenData.value)) {
        return;
      }

      const isFromGas = from === FilledByEnum.Gas;

      const estimatedAmounts = await FactoryApi.getEstimatedAmounts({
        tokenA: TokenUtils.getAddressBySymbol(TokensSymbolEnum.wIOTA),
        tokenB: TokenUtils.getAddressBySymbol(dataReward.symbol),
        amountADesired: isFromGas ? tokenData.value : '0',
        amountBDesired: isFromGas ? '0' : tokenData.value,
      });

      if (isFromGas) {
        setDataReward((data: FieldDataProps) => ({
          ...data,
          value: estimatedAmounts.amountB.toFixed(),
        }));
      } else {
        setDataGas((data: FieldDataProps) => ({
          ...data,
          value: estimatedAmounts.amountA.toFixed(),
        }));
      }

      setLpAmount(estimatedAmounts.lpAmount);
    },
    300
  );

  useEffect(() => FactoryApi.connect(FACTORY_ADDRESS, FACTORY_ABI, provider), [provider]);

  useEffect(() => {
    if (exectFrom === FilledByEnum.Gas) {
      debouncedFetchData(dataGas, FilledByEnum.Gas);
    }
  }, [dataGas, exectFrom]);

  useEffect(() => {
    if (exectFrom === FilledByEnum.Reward) {
      debouncedFetchData(dataReward, FilledByEnum.Reward);
    }
  }, [dataReward, exectFrom]);

  useEffect(() => {
    if (selectedTabFrom === ZapFromEnum.Vesting) {
      setExectFrom(FilledByEnum.Reward);
      setDataReward((data: FieldDataProps) => ({
        ...data,
        value: userRewardBalance.toString(),
      }));
    }
  }, [selectedTabFrom, onlyVestingMode, userRewardBalance]);

  useInterval(() => {
    debouncedFetchData(exectFrom === FilledByEnum.Gas ? dataGas : dataReward, exectFrom);
  }, 3000);

  return (
    <>
      <BasicModal isVisible={isOpen} onBackdropPress={onHandleClose} className="zap-modal">
        <BasicModal.Header className="zap-modal__header">
          {step === Steps.lockApr && (
            <span className="zap-modal__back" onClick={() => setStep(Steps.amount)}>
              <BackArrow /> Back
            </span>
          )}
          Zap into
        </BasicModal.Header>
        <BasicModal.Close onClose={onHandleClose} />

        <BasicBox className="zap-modal__emission">
          <p className="zap-modal__emission-title">
            {isEligible ? (
              <>You’re emissions eligible! 🔥</>
            ) : (
              <>You are not eligible for emissions! &#x1F61E;</>
            )}
          </p>
          <p className="zap-modal__emission-text">
            When the value of your locked wLP is equal to 5% of your total deposit value you will
            become eligible for emissions.
          </p>

          <div className="zap-modal__range">
            <EligibilityProgress className="zap-modal__eligibility" />
          </div>
        </BasicBox>

        {step === Steps.amount ? (
          <div className="zap-modal__fields">
            <Slippage {...{ slippage, setSlippage }} className="zap-modal__slippage" />
            <BasicAmountField className="zap-modal__field" maxAmount={userGasBalance}>
              {(maxAmount) => (
                <>
                  <BasicAmountField.InputBox>
                    <BasicAmountField.Input
                      className="zap-modal__field-input"
                      handleChange={(value) => handleInputChange(value, FilledByEnum.Gas)}
                      value={dataGas.value}
                      max={maxAmount}
                      decimals={18}
                      type="number"
                    />
                    <BasicAmountField.USDValue>
                      {Number(dataGas.value)
                        ? valueToBigNumber(dataGas.value).times(prices.wethPriceUsd)
                        : valueToBigNumber(0)}
                    </BasicAmountField.USDValue>
                  </BasicAmountField.InputBox>
                  <BasicAmountField.TokenBox>
                    <Select
                      selectedId={selectedItem}
                      onOptionChange={handleSelectChange}
                      className="zap-modal__field-select"
                    >
                      <Select.Header>
                        <BasicAmountField.Asset symbol={dataGas.symbol} />
                      </Select.Header>
                      <Select.Dropdown>
                        {SELECT_TOKENS.map((token: string) => (
                          <Select.Item key={token}>
                            <BasicAmountField.Asset symbol={token} />
                          </Select.Item>
                        ))}
                      </Select.Dropdown>
                    </Select>
                    <BasicAmountField.Balance
                      onClick={() => handleInputChange(maxAmount.toString(), FilledByEnum.Gas)}
                    >
                      {maxAmount.toString()}
                    </BasicAmountField.Balance>
                  </BasicAmountField.TokenBox>
                </>
              )}
            </BasicAmountField>

            <BasicAmountField className="zap-modal__field" maxAmount={userRewardBalance}>
              {(maxAmount) => (
                <>
                  <BasicAmountField.InputBox>
                    <BasicAmountField.Input
                      className="zap-modal__field-input"
                      handleChange={(value) => handleInputChange(value, FilledByEnum.Reward)}
                      value={dataReward.value}
                      max={maxAmount}
                      decimals={18}
                      type="number"
                    />
                    <BasicAmountField.USDValue className="za-modal__field-usd">
                      {Number(dataReward.value)
                        ? valueToBigNumber(dataReward.value).times(prices.tokenPriceUsd)
                        : valueToBigNumber(0)}
                    </BasicAmountField.USDValue>

                    <ButtonTabs
                      className="zap-modal__tabs"
                      tabs={TABS_OPTIONS}
                      setSelectedTab={handleTabChange}
                      selectedTab={selectedTabFrom}
                    />
                  </BasicAmountField.InputBox>
                  <BasicAmountField.TokenBox>
                    <BasicAmountField.Asset symbol={dataReward.symbol} />
                    <BasicAmountField.Balance
                      onClick={() => handleInputChange(maxAmount.toString(), FilledByEnum.Reward)}
                    >
                      {maxAmount.toString()}
                    </BasicAmountField.Balance>
                  </BasicAmountField.TokenBox>
                </>
              )}
            </BasicAmountField>
          </div>
        ) : (
          <ZapLockAprs selectedTabFrom={selectedTabFrom} />
        )}

        <DefaultButton
          size="medium"
          fill
          className="zap-modal__btn-continue"
          onClick={handleSubmit}
          disabled={isButtonDisabled || isOneMonthDisabled}
        >
          Continue
        </DefaultButton>

        <BasicBox className="zap-modal__list">
          <div className="zap-modal__list-item">
            <p>Total value of deposited assets</p>{' '}
            <span>$ {MathUtils.formatNumber(user?.totalLiquidityUSD, 2) || ''}</span>
          </div>
          <div className="zap-modal__list-item">
            <p>Required locked LP value for emissions</p>{' '}
            <span>
              $ {MathUtils.formatNumber(requiredLockedLP.times(prices.lpTokenPriceUsd), 2)}
            </span>
          </div>
          <div className="zap-modal__list-item">
            <p>Currently locked</p> <span>{MathUtils.formatNumber(userTotalLockedBalance, 2)}</span>
          </div>
        </BasicBox>
      </BasicModal>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => setStep(Steps.amount)}
      >
        <BasicModal.Close onClose={() => setStep(Steps.amount)} />
        {user && (
          <PoolTxConfirmationView
            mainTxName={'Zap'}
            caption={'Zap overview'}
            boxTitle={'Zap'}
            boxDescription={'Please submit to zap'}
            approveDescription={'Please approve before zaping'}
            getTransactionsData={handleGetTransactions}
            blockingError={blockingError}
            aTokenData={aTokenData}
          ></PoolTxConfirmationView>
        )}
      </BasicModal>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
