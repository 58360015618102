import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Footer {
    z-index: 2;
    padding: 10px 35px 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 76px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(254, 248, 232, 0.05);
    backdrop-filter: blur(20px);
    @include respond-to(sm) {
      display: none;
    }

    &__powered-by {
      margin: auto;
      width: 100px;
      color: rgba(0, 0, 0, 0.5);
    }

    &__inside {
      @include respond-to(md) {
        display: none;
      }
    }

    &__menu {
      display: flex;
      flex-direction: row;
      &__social {
        gap: 32px;
        display: flex;
        flex-direction: row;

        a {
          color: #00000099;
          font-weight: 400;
          font-size: 14px;

          &:hover {
            opacity: 0.7;
          }
        }

        svg {
          width: 26px;
        }
      }
    }
    &__block {
      color: #00000099;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &__point {
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: #1bc35c;
        margin-right: 4px;
      }
    }

    .DarkModeSwitcher {
      margin-right: 10px;
    }
  }
`;

export default staticStyles;
