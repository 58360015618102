import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .zap-apr {
    margin-top: 30px;

    @include respond-to(xs) {
      margin-top: 12px;
    }

    &__title {
      font-size: 14px;
      text-align: center;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 12px;
      text-align: center;
      margin: 20px 12px 0;

      @include respond-to(xs) {
        margin: 12px 0 0;
      }
    }

    &__item {
      min-width: 140px;
      max-width: 49%;
      border-radius: 12px;
      cursor: pointer;
      transition: background 0.3s, box-shadow 0.3s;

      @include respond-to(xs) {
        min-width: 100px;
        padding: 20px 12px;
      }

      &--active,
      &:hover {
        background-color: rgba(14, 12, 19, 0.1);
      }

      &--active {
        border-color: transparent;
        box-shadow: inset 5px 1px 15px rgba(0, 0, 0, 0.25),
          inset -2px -6px 11px rgba(255, 255, 255, 0.02);
      }
    }

    &__item-time {
      font-weight: 600;
      margin-bottom: 8px;
    }

    &__item-apr {
      display: inline-block;
      padding: 3px 12px 2px;
      font-size: 12px;
      border: 1px solid rgba(255, 255, 255, 0.03);
      border-radius: 4px;
      background-color: rgba(14, 12, 19, 0.1);
    }
  }
`;

export default staticStyles;
