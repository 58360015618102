import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as Twitter } from '../../images/icons/twitter-icon.svg';
import { ReactComponent as Discord } from '../../images/icons/discord-icon.svg';
import { ReactComponent as Docs } from '../../images/icons/docs-icon.svg';
import { ReactComponent as Audit } from '../../images/icons/audit-icon.svg';
import { ReactComponent as PoweredBy } from '../../images/powered-by.svg';

import staticStyles from './style';

import { useProviderContext } from '../../libs/provider/WalletProvider';
import { DISCORD_LINK, GITBOOK_LINK } from '../../shared';

interface FooterProps {
  inside?: boolean;
}

export default function Footer({ inside }: FooterProps) {
  const [blockNumber, setBlockNumber] = useState('Fetching...');
  const { provider } = useProviderContext();

  useEffect(() => {
    provider.getBlockNumber().then((number) => {
      setBlockNumber(number.toString());
    });
  }, [provider]);

  return (
    <footer className={classNames('Footer', { Footer__inside: inside })}>
      <div className="Footer__menu">
        <div className="Footer__menu__social">
          <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
            <Discord />
          </a>
          <a href="https://X.com/iolendfi" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
          <a href={GITBOOK_LINK} target="_blank" rel="noreferrer">
            <Docs />
          </a>
          <a href={`${GITBOOK_LINK}iolend/audit`} target="_blank" rel="noreferrer">
            <Audit />
          </a>
        </div>
      </div>
      <PoweredBy className="Footer__powered-by" />
      <div className="Footer__block">
        <div className="Footer__block__point"></div>
        Block: {blockNumber}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </footer>
  );
}
