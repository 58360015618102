import { useMemo, useState } from 'react';
import { useBalanceContext } from '../../libs/wallet-balance-provider/BalanceProvider';
import useRewardTokenPrices from '../../store/tokens-price/tokens-price.hooks';
import { MathUtils } from '../../utils/math.utils';
import { BasicBox } from '../BasicBox/BasicBox';
import staticStyles from './style';
import { valueToBigNumber } from '@aave/protocol-js';
import DefaultButton from '../basic/DefaultButton';
import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { ZapModal } from '../ZapModal/ZapModal';
import {
  DAYS_IN_YEAR,
  GITBOOK_LINK,
  REWARD_ADDRESS,
  TokensSymbolEnum,
  wIOTA_ADDRESS,
} from '../../shared';
import { EligibilityProgressChart } from './components/EligibilityProgress/EligibilityProgressChart';
import { ReactComponent as LinkIcon } from '../../images/icons/link-icon.svg';
import PreloaderOver from '../basic/PreloaderOver';
import { useEligibility } from './components/EligibilityProgress/useEligibility';
import { AIStrategy } from './components/AIStrategy/AIStrategy';

export function EmissionsRow() {
  const { user } = useDynamicPoolDataContext();
  const {
    claimableRewards,
    allPendingRewards,
    userRevenuePerDay,
    userTotalLockedBalance,
    totalClaimableRewardsInUsd,
  } = useBalanceContext();

  const {
    prices: { lpTokenPriceUsd, tokenPriceUsd, lpTokenPrice },
  } = useRewardTokenPrices();
  const { isEligible } = useEligibility();
  const [zapIsOpen, setZapIsOpen] = useState<boolean>(false);

  const userAverageApr = useMemo(() => {
    const apr = userRevenuePerDay
      .times(DAYS_IN_YEAR)
      .div(userTotalLockedBalance)
      .times(lpTokenPrice)
      .times(100);

    if (!apr.isNaN()) {
      return apr;
    } else {
      return valueToBigNumber(0);
    }
  }, [lpTokenPrice, userRevenuePerDay, userTotalLockedBalance]);

  const { eligibilityPercent } = useEligibility();

  const isLoaded = useMemo(
    () =>
      claimableRewards.length > 0 &&
      userRevenuePerDay.isPositive() &&
      userTotalLockedBalance.isPositive() &&
      allPendingRewards.isPositive() &&
      Number(lpTokenPriceUsd) > -1 &&
      eligibilityPercent.isPositive() &&
      userTotalLockedBalance.isPositive(),
    [claimableRewards, userRevenuePerDay, lpTokenPriceUsd, allPendingRewards, eligibilityPercent]
  );

  const onHandleZapClose = () => {
    setZapIsOpen(false);
  };

  return (
    <>
      {user ? (
        <div className="emissions-wrapper">
          <BasicBox className="emissions" variant="secondary">
            <BasicBox.Body className="emissions__body">
              <BasicBox.Header className="emissions__header">
                <BasicBox.Title>
                  {isEligible ? 'Emissions Active' : 'Emissions Inactive '}
                </BasicBox.Title>

                <div className="emissions__btns-group emissions__btns-group--sm-hidden">
                  <DefaultButton
                    className="emissions__btn"
                    size="small"
                    color="secondary"
                    onClick={() => setZapIsOpen(true)}
                  >
                    Zap into LP
                  </DefaultButton>
                  <DefaultButton
                    className="emissions__btn"
                    size="small"
                    color="secondary"
                    type="link"
                    href={GITBOOK_LINK}
                  >
                    Learn more <LinkIcon />
                  </DefaultButton>
                </div>
              </BasicBox.Header>

              <p className="emissions__text">
                {isEligible
                  ? `You are earning ${TokensSymbolEnum.REWARD} incentives on your deposits and borrows.`
                  : `You are not earning ${TokensSymbolEnum.REWARD} incentives on your deposits and borrows`}
              </p>
              <div className="emissions__group">
                <div className="emissions__badge">
                  <p className="emissions__badge-name">wLP Locked</p>
                  <p className="emissions__badge-value ff-lg">
                    $ {MathUtils.toLocaleNumber(userTotalLockedBalance.times(lpTokenPriceUsd), 2)}
                  </p>
                </div>
                <div className="emissions__badge">
                  <p className="emissions__badge-name">Avarage apr</p>
                  <p className="emissions__badge-value ff-lg">
                    {MathUtils.toLocaleNumber(userAverageApr, 2)}%
                  </p>
                </div>
                <div className="emissions__badge">
                  <p className="emissions__badge-name">Claimable rewards</p>
                  <p className="emissions__badge-value ff-lg">
                    ${' '}
                    {MathUtils.toLocaleNumber(
                      totalClaimableRewardsInUsd.plus(allPendingRewards.times(tokenPriceUsd)),
                      2
                    )}
                  </p>
                </div>

                <DefaultButton
                  color="neutral"
                  className="emissions__buy-btn"
                  type="link"
                  size="small"
                  href={`https://app.wagmi.com/trade/swap#/trade/swap?inputCurrency=${wIOTA_ADDRESS}&outputCurrency=${REWARD_ADDRESS}`}
                >
                  Buy {TokensSymbolEnum.REWARD}
                  <LinkIcon />
                </DefaultButton>
              </div>

              <div className="emissions__btns-group emissions__btns-group--sm-show">
                <DefaultButton
                  className="emissions__btn"
                  size="small"
                  color="secondary"
                  onClick={() => setZapIsOpen(true)}
                >
                  Zap into LP
                </DefaultButton>
                <DefaultButton
                  className="emissions__btn"
                  size="small"
                  color="secondary"
                  type="link"
                  href={GITBOOK_LINK}
                >
                  Learn more <LinkIcon />
                </DefaultButton>
              </div>
            </BasicBox.Body>

            <EligibilityProgressChart />
            <PreloaderOver isShown={!isLoaded} />
          </BasicBox>

          {/* <AIStrategy /> */}
        </div>
      ) : null}
      <ZapModal isOpen={zapIsOpen} onHandleClose={onHandleZapClose} />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
