import { useCallback, useEffect, useMemo, useState } from 'react';
import InputBar from '../../../../components/basic/InputBar';
import { MathUtils } from '../../../../utils/math.utils';
import { ToolsForm } from '../ToolsForm/ToolsForm';
import { useToolsStore } from '../../../../store';
import { BasicAmountField } from '../../../../components/BasicAmountField/BasicAmountField';
import { valueToBigNumber } from '@aave/math-utils';
import BigNumber from 'bignumber.js';
import { ToolsHelper } from '../../tools.helper';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import { FlashLoanBorrow, FLASHLOAN_BORROW_ADDRESS } from '../../../../libs/FlashLoanBorrow';
import { BasicModal, getAssetInfo } from '../../../../libs/aave-ui-kit';
import PoolTxConfirmationView from '../../../../components/PoolTxConfirmationView';
import { ComputedReserveData, UserSummary } from '../../../../libs/pool-data-provider';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import { Address } from 'viem';
import { ReserveWithBalance } from '../../hooks';
import { LeverageTabs } from '../../Leverage';
import { MIN_LEVERAGE } from '../../tools.constants';
import { SwapLoopContract } from '../../../../libs/SwapLoopContract';
import { SWAPLOOP_CONTRACT_ADDRESS } from '../../../../libs/SwapLoopContract/typechain/factories/SwapLoop__factory';
import { useDebouncedCallback } from 'use-debounce';
import { Slippage } from '../../../../components';

enum Steps {
  amount = 'amount',
  confirmation = 'confirmation',
  finished = 'finished',
  approve = 'approve',
}

export function LeverageForm({
  selectedA,
  selectedB,
  user,
  leverageFrom,
  maxLeverage,
}: {
  selectedA: ReserveWithBalance | null;
  selectedB: ComputedReserveData | null;
  user: UserSummary;
  leverageFrom: LeverageTabs;
  maxLeverage: BigNumber;
}) {
  const [slippage, setSlippage] = [useToolsStore.use.slippage(), useToolsStore.use.setSlippage()];

  const { provider } = useProviderContext();

  const [amountA, setAmountA] = [useToolsStore.use.amountA(), useToolsStore.use.setAmountA()];
  const [amountB, setAmountB] = [useToolsStore.use.amountB(), useToolsStore.use.setAmountB()];
  const [leverage, setLeverage] = [useToolsStore.use.leverage(), useToolsStore.use.setLeverage()];
  const [step, setStep] = useState<Steps>(Steps.amount);
  const [txError, setTxError] = useState<string>();
  // const [maxBorrowAmount, setMaxBorrowAmount] = useState<string>('-');

  const isASelected = selectedA !== null;
  const isBSelected = selectedB !== null;
  const isSelected = isASelected && isBSelected;

  const debouncedFetchBorrowingResults = useDebouncedCallback(async (args) => {
    const borrowAmount = await contract.getBorrowAmount(args);

    setAmountB(borrowAmount);
  }, 300);

  const isDisabled = useMemo(() => {
    const bigIntA = valueToBigNumber(amountA);

    if (isSelected) {
      return !bigIntA.gt(0) || bigIntA.gt(selectedA.balance);
    } else {
      return true;
    }
  }, [isSelected, selectedA, selectedB, amountA]);

  const contract = useMemo(() => {
    const borrowAmount = valueToBigNumber(amountA).times(leverage).minus(amountA);

    const isEnoughLiquidity = selectedA ? borrowAmount.lte(selectedA.availableLiquidity) : true;

    return isEnoughLiquidity
      ? new FlashLoanBorrow(provider, FLASHLOAN_BORROW_ADDRESS)
      : new SwapLoopContract(provider, SWAPLOOP_CONTRACT_ADDRESS);
  }, [provider, selectedA, amountA, leverage]);

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      if (isSelected) {
        setStep(Steps.confirmation);
      }
    },
    [provider, selectedA, amountA, amountB, leverage, selectedB]
  );

  const handleGetTransactions = useCallback(() => {
    return contract.flashBorrow({
      user: user.id as Address,
      collateral: selectedA as ReserveWithBalance,
      collateralAmount: amountA,
      borrow: selectedB as ComputedReserveData,
      borrowAmount: amountB,
      leverage,
      leverageFrom,
      slippage,
    });
  }, [amountA, amountB, maxLeverage, provider, slippage, contract]);

  useEffect(() => {
    if (selectedA && selectedB && Number(amountA)) {
      debouncedFetchBorrowingResults({
        collateral: selectedA,
        collateralAmount: amountA,
        borrow: selectedB,
        leverage,
      });
    }
  }, [amountA, leverage, selectedB]);

  // useEffect(() => {
  //   if (selectedA && selectedB) {
  //     (async () => {
  //       const borrowAmount = await contract.getBorrowAmount({
  //         collateral: selectedA,
  //         collateralAmount: selectedA.balance,
  //         borrow: selectedB,
  //         leverage: maxLeverage.toNumber(),
  //       });

  //       setMaxBorrowAmount(borrowAmount.toString());
  //     })();
  //   }
  // }, [selectedA, selectedB, maxLeverage]);

  return (
    <>
      <ToolsForm {...{ handleSubmit }}>
        <ToolsForm.Field title="Deposit">
          <Slippage {...{ slippage, setSlippage }} className="tools-form__slippage" />
          <BasicAmountField className="tools-form__field" size="small">
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                className="tools-form__input"
                handleChange={setAmountA}
                type="number"
                value={amountA}
                decimals={selectedA?.decimals}
              />
              <BasicAmountField.USDValue>
                {isSelected
                  ? MathUtils.toLocaleNumber(
                      valueToBigNumber(amountA).times(selectedA?.priceInMarketReferenceCurrency),
                      2
                    )
                  : 0}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              {isASelected && (
                <>
                  <BasicAmountField.Asset symbol={selectedA?.symbol} />
                  <BasicAmountField.Balance
                    className="tools-form__balance"
                    onClick={() => setAmountA(selectedA.balance)}
                  >
                    {selectedA?.balance}
                  </BasicAmountField.Balance>
                </>
              )}
            </BasicAmountField.TokenBox>
          </BasicAmountField>
        </ToolsForm.Field>
        <ToolsForm.Field title="Borrow">
          <BasicAmountField className="tools-form__field" size="small">
            <BasicAmountField.InputBox>
              <BasicAmountField.Input
                className="tools-form__input"
                handleChange={() => {}}
                value={amountB}
              />
              <BasicAmountField.USDValue>
                {MathUtils.toLocaleNumber(
                  valueToBigNumber(amountB).times(selectedB?.priceInMarketReferenceCurrency || 0),
                  2
                )}
              </BasicAmountField.USDValue>
            </BasicAmountField.InputBox>
            <BasicAmountField.TokenBox>
              {isBSelected && (
                <>
                  <BasicAmountField.Asset symbol={selectedB?.symbol} />
                  {leverageFrom === LeverageTabs.Deposit ? (
                    <BasicAmountField.Balance
                      label="available to borrow:"
                      className="tools-form__balance"
                      onClick={() => {}}
                    >
                      {MathUtils.toLocaleNumber(selectedB.availableLiquidity, 2)}
                    </BasicAmountField.Balance>
                  ) : null}
                </>
              )}
            </BasicAmountField.TokenBox>
          </BasicAmountField>
        </ToolsForm.Field>

        <ToolsForm.Range value={leverage} min={MIN_LEVERAGE} max={Number(maxLeverage)}>
          {({ rate, min, max }) => (
            <>
              <InputBar.Header>
                <span>{min}</span>
                <span>
                  Leverage{' '}
                  <span className="tools-form__leverage-amount">
                    x{MathUtils.truncateNumber(leverage, 2)}
                  </span>
                </span>
                <span>{max}</span>
              </InputBar.Header>
              <InputBar.Range
                onChange={(leverage) => {
                  if (selectedA && selectedB) {
                    setLeverage(Number(leverage.toFixed(1)));
                  }
                }}
                minAmount={min}
                maxAmount={max}
                value={leverage}
              >
                <InputBar.Progress rate={rate} />
              </InputBar.Range>
            </>
          )}
        </ToolsForm.Range>

        <ToolsForm.Button disabled={isDisabled}>Leverage</ToolsForm.Button>
      </ToolsForm>

      <BasicModal
        isVisible={step === Steps.confirmation}
        onBackdropPress={() => {
          setStep(Steps.amount);
          setTxError('');
        }}
      >
        <BasicModal.Close
          onClose={() => {
            setStep(Steps.amount);
            setTxError('');
          }}
        />
        {isSelected ? (
          <PoolTxConfirmationView
            mainTxName={'Leverage'}
            caption={'Leverage overview'}
            boxTitle={'Leverage'}
            boxDescription={'Please submit to leverage'}
            approveDescription={'Please approve before leverage'}
            getTransactionsData={handleGetTransactions}
            blockingError={txError}
            onTxError={(e) => {
              const error = ToolsHelper.getErrorData(e.message);

              console.log(e);

              if (error) {
                setTxError(error.text);
              }
            }}
            aTokenData={getAtokenInfo({
              address: selectedB.underlyingAsset,
              symbol: getAssetInfo(selectedB.symbol).symbol,
              decimals: selectedB.decimals,
            })}
          />
        ) : null}
      </BasicModal>
    </>
  );
}
