import { useState } from 'react';
import InputBar from '../basic/InputBar';
import { BasicModal } from '../../libs/aave-ui-kit';
import { MathUtils } from '../../utils/math.utils';

import { ReactComponent as SettingIcon } from '../../images/icons/settings-icon.svg';
import { MAX_SLIPPAGE, MIN_SLIPPAGE } from '../../modules/tools/tools.constants';
import classNames from 'classnames';

export function Slippage({
  slippage,
  setSlippage,
  className,
}: {
  slippage: number;
  setSlippage: (slippage: number) => void;
  className?: string;
}) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className={classNames('slippage', className)}>
        Slippage: <span className="slippage__value">{slippage}%</span>
        <SettingIcon width={20} onClick={() => setModalOpen(true)} />
      </div>
      <BasicModal
        isVisible={modalOpen}
        onBackdropPress={() => setModalOpen(false)}
        className="slippage__modal"
      >
        <BasicModal.Header>Adjust Slippage</BasicModal.Header>
        <BasicModal.Close onClose={() => setModalOpen(false)} />
        <BasicModal.Content>
          <InputBar minAmount={MIN_SLIPPAGE} maxAmount={MAX_SLIPPAGE} value={slippage}>
            {({ rate, min, max }) => (
              <>
                <InputBar.Header className="slippage__modal-header">
                  <span className="slippage__modal-min">{min}%</span>
                  <span>
                    Slippage:{' '}
                    <span className="slippage__modal-value">
                      {MathUtils.truncateNumber(slippage, 2)}%
                    </span>
                  </span>
                  <span className="slippage__modal-max">{max}%</span>
                </InputBar.Header>
                <InputBar.Range
                  onChange={(slippage) => {
                    setSlippage(Number(slippage.toFixed(1)));
                  }}
                  minAmount={min}
                  maxAmount={max}
                  value={slippage}
                  className="slippage__modal-range"
                >
                  <InputBar.Progress rate={rate} />
                </InputBar.Range>
              </>
            )}
          </InputBar>
        </BasicModal.Content>
      </BasicModal>

      <style jsx global>{`
        .slippage {
          display: inline-flex;
          align-items: center;
          gap: 4px;

          svg {
            color: rgba(0, 0, 0, 0.6);
            cursor: pointer;
            transition: color 0.2s;

            &:hover {
              color: rgba(0, 0, 0, 0.8);
            }
          }

          &__value {
            font-weight: 500;
          }

          &__modal {
            width: 380px;
            max-width: 100%;
          }

          &__modal-value {
            font-weight: 500;
          }
        }
      `}</style>
    </>
  );
}
