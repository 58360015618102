import { useEffect, useState } from 'react';
import { useWalletBalanceProviderContext } from '../../../libs/wallet-balance-provider/WalletBalanceProvider';
import { ComputedReserveData, useDynamicPoolDataContext } from '../../../libs/pool-data-provider';
import { ToolsHelper } from '../tools.helper';

export interface ReserveWithBalance extends ComputedReserveData {
  balance: string;
}

export function useWalletTokens() {
  const [walletTokens, setWalletTokens] = useState<ReserveWithBalance[]>();
  const { walletData, loading } = useWalletBalanceProviderContext();
  const { reserves, user } = useDynamicPoolDataContext();

  useEffect(() => {
    if (reserves.length && Object.keys(walletData).length) {
      const userTokens = ToolsHelper.filterZeroAmount(reserves, walletData);

      setWalletTokens(userTokens);
    }
  }, [user, walletData, reserves]);

  return { walletTokens, walletDataLoading: loading };
}
