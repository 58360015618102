import { ErrorNamesEnum } from './tools.enums';
import { ErrorMessageType } from './tools.types';

export const LEVERAGE_SLIPPAGE = 0.01;
export const PREMIUM_FEE = 0.001;
export const MIN_LEVERAGE = 1.1;
export const MIN_SLIPPAGE = 0.1;
export const MAX_SLIPPAGE = 10;

export const ERROR_OPTIONS: ErrorMessageType[] = [
  {
    name: ErrorNamesEnum.SwapThresholdExceeded,
    text: 'Swap conditions are unfavorable. Please adjust the amount or try again later.',
  },
  {
    name: ErrorNamesEnum.WithdrawThreshold,
    text: 'Withdrawal conditions are unfavorable. Please adjust the amount or try again later.',
  },
  {
    name: ErrorNamesEnum.ThresholdOutOfRange,
    text: 'Invalid percentage provided. Please increase the slippage value.',
  },
  {
    name: ErrorNamesEnum.ImpossibleRepayFlashLoan,
    text: 'Leverage price impact is too high. Please try smaller amount or reduce leverage.',
  },
];
