import { useCallback, useEffect, useMemo, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import ButtonTabs from '../../components/basic/ButtonTabs';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import { BasicBox } from '../../components/BasicBox/BasicBox';
import staticStyles from './style';
import { ToolsInfo, ToolsInfoList } from './components/ToolsInfo/ToolsInfo';
import BasicTable from '../../components/BasicTable';
import { ComputedReserveData, useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { ReserveWithBalance, useWalletTokens } from './hooks/useWalletTokens';
import { LeverageForm, ToolRow } from './components';
import { usePoolsInfoData, useToolsStore } from '../../store';
import { valueToBigNumber } from '@aave/math-utils';
import NoDataPanel from '../../components/NoDataPanel';
import PreloaderOver from '../../components/basic/PreloaderOver';
import { useUserPositions } from '../../hooks';
import { ToolsHelper } from './tools.helper';
import BigNumber from 'bignumber.js';
import { BorrowWidgetHelper } from '../../components/BorrowWidget/borrow-widget.helper';

export enum LeverageTabs {
  Wallet = 'Wallet',
  Deposit = 'Deposit',
}

type LeverageTabsType = LeverageTabs.Wallet | LeverageTabs.Deposit;

const TOOLS_TABS: LeverageTabsType[] = [LeverageTabs.Wallet, LeverageTabs.Deposit];

export default function Leverage() {
  const leverage = useToolsStore.use.leverage();

  const { depositedPositions, isLoaded } = useUserPositions();
  const { reserves, user } = useDynamicPoolDataContext();
  const { poolsInfoData } = usePoolsInfoData();
  const { walletTokens, walletDataLoading } = useWalletTokens();
  const [currentTab, setCurrentTab] = useState<LeverageTabsType>(TOOLS_TABS[0]);
  const [maxLeverage, setMaxLeverage] = useState<BigNumber>(valueToBigNumber(10));
  const [selectedA, setSelectedA] = [
    useToolsStore.use.selectedA(),
    useToolsStore.use.setSelectedA(),
  ];
  const [selectedB, setSelectedB] = [
    useToolsStore.use.selectedB(),
    useToolsStore.use.setSelectedB(),
  ];
  const [amountA, setAmountA] = [useToolsStore.use.amountA(), useToolsStore.use.setAmountA()];
  const [amountB, setAmountB] = [useToolsStore.use.amountB(), useToolsStore.use.setAmountB()];
  const [walletSelected, setWalletSelected] = useState<ReserveWithBalance | null>(null);
  const [depositSelected, setDepositSelected] = useState<ReserveWithBalance | null>(null);

  const depositReserves: ReserveWithBalance[] = useMemo(
    () => ToolsHelper.getReservesWithBalance({ reserves, positions: depositedPositions }),
    [depositedPositions, reserves]
  );

  const handleWalletSelect = useCallback(
    (selectedA: ReserveWithBalance) => {
      if (selectedB?.symbol !== selectedA.symbol) {
        setWalletSelected(selectedA);
        setSelectedA(selectedA);
      }
    },
    [selectedB]
  );

  const handleDepositSelect = useCallback(
    (selectedA: ReserveWithBalance) => {
      if (selectedB?.symbol !== selectedA.symbol) {
        setDepositSelected(selectedA);
        setSelectedA(selectedA);
      }
    },
    [selectedB]
  );

  const handleBorrowSelect = useCallback(
    (selectedB: ComputedReserveData) => {
      if (selectedB.symbol !== selectedA?.symbol && user) {
        selectedB.availableLiquidity = BorrowWidgetHelper.calculateUserAvailableBorrowAmount(
          user,
          selectedB
        ).toString();

        setSelectedB(selectedB);
      }
    },
    [selectedA, user, currentTab]
  );

  useEffect(() => {
    if (currentTab === LeverageTabs.Wallet) {
      setSelectedA(walletSelected?.symbol !== selectedB?.symbol ? walletSelected : null);
    } else {
      setSelectedA(depositSelected?.symbol !== selectedB?.symbol ? depositSelected : null);
    }
  }, [currentTab]);

  useEffect(() => {
    if (selectedA) {
      console.log(selectedA.baseLTVasCollateral);

      const maxLeverage = ToolsHelper.calculateMaxLeverage(selectedA.baseLTVasCollateral, 1);

      setMaxLeverage(maxLeverage);
    }
  }, [selectedA]);

  useEffect(() => {
    return () => {
      setSelectedA(null);
      setAmountA('');
      setSelectedB(null);
      setAmountB('');
    };
  }, []);

  return (
    <>
      <ScreenWrapper className="tools">
        <PageHeader text="Leverage" />
        <p className="tools__subtitle">Available to leverage</p>

        <div className="tools__section">
          <BasicBox className="tools__section-inner">
            <BasicBox.Header className="tools__section-header">
              <BasicBox.Title>Choose asset to leverage</BasicBox.Title>

              <ButtonTabs
                className="tools__table-tabs"
                tabs={TOOLS_TABS}
                selectedTab={currentTab}
                setSelectedTab={setCurrentTab}
              />
            </BasicBox.Header>
            <BasicBox.Body>
              {user ? (
                <BasicTable>
                  {currentTab === LeverageTabs.Wallet ? (
                    !walletDataLoading && walletTokens && walletTokens?.length > 0 ? (
                      <>
                        <BasicTable.Header className="tools__table-header">
                          <BasicTable.Item>Assets</BasicTable.Item>
                          <BasicTable.Item>Balances</BasicTable.Item>
                          <BasicTable.Item>APY</BasicTable.Item>
                        </BasicTable.Header>
                        {walletTokens.map((walletToken, index) => (
                          <ToolRow
                            key={index}
                            handleClick={() => handleWalletSelect(walletToken)}
                            isActive={walletToken.symbol === selectedA?.symbol}
                            isDisabled={walletToken.symbol === selectedB?.symbol}
                            price={walletToken.priceInMarketReferenceCurrency}
                            symbol={walletToken.symbol}
                            amount={walletToken.balance}
                            apy={walletToken.supplyAPY}
                            isDeposit
                          />
                        ))}
                      </>
                    ) : (
                      <BasicBox.Body>No asset available</BasicBox.Body>
                    )
                  ) : (
                    <>
                      {isLoaded && depositReserves.length > 0 ? (
                        <>
                          <BasicTable.Header className="tools__table-header">
                            <BasicTable.Item>Assets</BasicTable.Item>
                            <BasicTable.Item>Balances</BasicTable.Item>
                            <BasicTable.Item>APY</BasicTable.Item>
                          </BasicTable.Header>
                          {depositReserves.map((depositReserve, index) => (
                            <ToolRow
                              key={index}
                              handleClick={() => handleDepositSelect(depositReserve)}
                              isActive={depositReserve.symbol === selectedA?.symbol}
                              isDisabled={depositReserve.symbol === selectedB?.symbol}
                              symbol={depositReserve.symbol}
                              amount={depositReserve.balance}
                              price={depositReserve.priceInMarketReferenceCurrency}
                              apy={depositReserve.supplyAPY}
                              isDeposit
                            />
                          ))}
                        </>
                      ) : (
                        <BasicBox.Body>No asset available</BasicBox.Body>
                      )}
                    </>
                  )}
                </BasicTable>
              ) : (
                <NoDataPanel
                  title="Please connect your wallet"
                  withConnectButton
                  className="tools__connect"
                />
              )}
            </BasicBox.Body>
            <PreloaderOver
              isShown={user === undefined && (walletDataLoading || !poolsInfoData.length)}
            />
          </BasicBox>
          <BasicBox className="tools__section-inner">
            <BasicBox.Header className="tools__section-header">
              <BasicBox.Title>Choose asset to borrow</BasicBox.Title>
            </BasicBox.Header>
            <BasicBox.Body>
              <BasicTable className="tools__table tools__table--active">
                <BasicTable.Header className="tools__table-header">
                  <BasicTable.Item>Assets</BasicTable.Item>
                  <BasicTable.Item>Interest</BasicTable.Item>
                </BasicTable.Header>

                {user
                  ? reserves.map((reserve, index) => (
                      <ToolRow
                        key={index}
                        handleClick={() => handleBorrowSelect(reserve)}
                        isActive={reserve.symbol === selectedB?.symbol}
                        isDisabled={reserve.symbol === selectedA?.symbol}
                        symbol={reserve.symbol}
                        price={reserve.priceInMarketReferenceCurrency}
                        apy={reserve.variableBorrowAPY}
                        available
                      />
                    ))
                  : null}
              </BasicTable>
            </BasicBox.Body>
            <PreloaderOver
              isShown={user === undefined && (!reserves.length || !poolsInfoData.length)}
            />
          </BasicBox>
        </div>

        {user ? (
          <BasicBox className="tools__summery">
            <BasicBox.Header>
              <BasicBox.Title>Summary</BasicBox.Title>
            </BasicBox.Header>
            <BasicBox.Body className="tools__summery-body">
              <>
                <LeverageForm
                  selectedA={selectedA as ReserveWithBalance}
                  selectedB={selectedB}
                  user={user}
                  leverageFrom={currentTab}
                  maxLeverage={maxLeverage}
                />
                <ToolsInfo>
                  <ToolsInfo.Group>
                    <ToolsInfo.Position
                      item={selectedA}
                      amount={valueToBigNumber(amountA).times(leverage)}
                      title="Long position"
                    />
                    <ToolsInfo.Position
                      item={selectedB}
                      amount={valueToBigNumber(amountB)}
                      title="Short position"
                    />
                  </ToolsInfo.Group>
                  <ToolsInfoList />
                </ToolsInfo>
              </>
            </BasicBox.Body>
          </BasicBox>
        ) : null}
      </ScreenWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
