import { useMemo } from 'react';
import { BasicBox } from '../../../../components/BasicBox/BasicBox';
import staticStyles from './style';
import { MathUtils } from '../../../../utils/math.utils';
import useRewardTokenPrices from '../../../../store/tokens-price/tokens-price.hooks';
import BigNumber from 'bignumber.js';
import { useBalanceContext } from '../../../../libs/wallet-balance-provider/BalanceProvider';
import { UserSummary } from '../../../../libs/pool-data-provider';
import { DAYS_IN_YEAR } from '../../../../shared';
import classNames from 'classnames';

export function ManageTopSectoin({
  totalLockedSupply,
  user,
}: {
  totalLockedSupply: BigNumber;
  user?: UserSummary;
}) {
  const {
    userTotalLockedBalance,
    userTotalLockedBalanceWithMultiplier,
    claimableRewards,
    userRevenuePerDay,
    userShare,
  } = useBalanceContext();
  const {
    prices: { lpTokenPriceUsd },
  } = useRewardTokenPrices();

  const { marketLockSizeLabel, marketLockSizeValue } = useMemo(() => {
    if (totalLockedSupply?.isNegative()) {
      return { marketLockSizeLabel: -1, marketLockSizeValue: -1 };
    } else {
      return {
        marketLockSizeLabel: Number(totalLockedSupply),
        marketLockSizeValue: Number(totalLockedSupply) * Number(lpTokenPriceUsd),
      };
    }
  }, [totalLockedSupply, lpTokenPriceUsd]);

  const claimableRewardsSize: number = claimableRewards.filter((reward) =>
    reward.amount.gt(0)
  ).length;

  return (
    <>
      <div className={classNames('m-top', !user && 'm-top__column')}>
        <BasicBox className="m-top__inner">
          <div className="m-top__item">
            <p className="m-top__item-txt">Total locked</p>
            {marketLockSizeLabel > -1 ? (
              <>
                <p className="m-top__item-value ff-lg">
                  {MathUtils.toLocaleNumber(marketLockSizeLabel, 2)}
                </p>
                <p className="m-top__item-subtext">
                  $ {MathUtils.toLocaleNumber(marketLockSizeValue, 2)}
                </p>
              </>
            ) : (
              <>
                <p className="m-top__item-value ff-lg">Fetching...</p>

                <p className="m-top__item-subtext"></p>
              </>
            )}
          </div>
          {/* <div className="m-top__item">
            <p className="m-top__item-txt">Total platform fees</p>
            {totalPlatformFees > -1 ? (
              <>
                <p className="m-top__item-value ff-lg">
                  {MathUtils.toLocaleNumber(totalPlatformFees, 2)}
                </p>
                <p className="m-top__item-subtext">{reserves.length} assets</p>
              </>
            ) : (
              <>
                <p className="m-top__item-value ff-lg">Fetching...</p>
                <p className="m-top__item-subtext"></p>
              </>
            )}
          </div> */}
        </BasicBox>
        <BasicBox className="m-top__inner">
          <div className="m-top__item">
            <p className="m-top__item-txt">Your wLP locked</p>
            {userTotalLockedBalance.isPositive() ? (
              <>
                <p className="m-top__item-value ff-lg">
                  {MathUtils.toLocaleNumber(userTotalLockedBalance, 2)}
                </p>
                <p className="m-top__item-subtext">
                  ${' '}
                  {MathUtils.toLocaleNumber(
                    userTotalLockedBalance.multipliedBy(lpTokenPriceUsd),
                    2
                  )}{' '}
                </p>
              </>
            ) : (
              <>
                <p className="m-top__item-value ff-lg">Fetching...</p>
                <p className="m-top__item-subtext"></p>
              </>
            )}
          </div>
          <div className="m-top__item">
            <p className="m-top__item-txt">Your share</p>
            {!userTotalLockedBalance.isNegative() ? (
              <>
                <p className="m-top__item-value ff-lg">{MathUtils.formatNumber(userShare, 2)}%</p>
                <p className="m-top__item-subtext">
                  Avg. multiplier:{' '}
                  {MathUtils.formatNumber(
                    userTotalLockedBalanceWithMultiplier.div(userTotalLockedBalance),
                    2
                  )}
                  x
                </p>
              </>
            ) : (
              <>
                <p className="m-top__item-value ff-lg">Fetching...</p>
                <p className="m-top__item-subtext"></p>
              </>
            )}
          </div>
          <div className="m-top__item">
            <p className="m-top__item-txt">Your annual revenue</p>
            {userRevenuePerDay.isPositive() ? (
              <>
                <p className="m-top__item-value m-top__item-value--highdivght ff-lg">
                  $ {MathUtils.toLocaleNumber(userRevenuePerDay.times(DAYS_IN_YEAR), 2)}
                </p>
                <p className="m-top__item-subtext">{claimableRewardsSize} assets</p>
              </>
            ) : (
              <>
                <p className="m-top__item-value m-top__item-value--highdivght">Fetching...</p>
                <p className="m-top__item-subtext"></p>
              </>
            )}
          </div>
        </BasicBox>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
