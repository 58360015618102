import { createSelectors } from '../createSelectors';
import { create } from '../../libs/zustand';
import { StoreToolsModel } from './tools.model';

export const useToolsStore = createSelectors(
  create<StoreToolsModel>((set) => ({
    selectedA: null,
    selectedB: null,
    amountA: '',
    amountB: '',
    leverage: 1.1,
    slippage: 1,
    shortPosition: null,
    longPosition: null,
    updateData: (obj: Record<string, unknown>) => {
      set((state) => ({
        ...state,
        ...obj,
      }));
    },
    setSelectedA: (selectedA) => {
      set((state) => ({
        ...state,
        selectedA,
      }));
    },
    setSelectedB: (selectedB) => {
      set((state) => ({
        ...state,
        selectedB,
      }));
    },
    setLeverage: (leverage) => {
      set((state) => ({
        ...state,
        leverage,
      }));
    },
    setAmountA: (amount) => {
      set((state) => ({
        ...state,
        amountA: amount,
      }));
    },
    setAmountB: (amount) => {
      set((state) => ({
        ...state,
        amountB: amount,
      }));
    },
    setSlippage: (slippage) => {
      set((state) => ({
        ...state,
        slippage,
      }));
    },
  }))
);
