import { useCallback, useEffect, useMemo } from 'react';
import PageHeader from '../../components/PageHeader';
import ButtonTabs from '../../components/basic/ButtonTabs';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import { BasicBox } from '../../components/BasicBox/BasicBox';
import staticStyles from './style';
import { ToolsInfo } from './components/ToolsInfo/ToolsInfo';
import BasicTable from '../../components/BasicTable';
import { ComputedReserveData, useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import { ReserveWithBalance } from './hooks/useWalletTokens';
import { DeleverageForm, ToolRow } from './components';
import { useToolsStore } from '../../store';
import { useUserPositions } from '../../hooks';
import PreloaderOver from '../../components/basic/PreloaderOver';
import NoDataPanel from '../../components/NoDataPanel';
import { ToolsHelper } from './tools.helper';
import { valueToBigNumber } from '@aave/math-utils';

const DELEVERAG_TABS: string[] = ['Deposit'];

export default function Deleverage() {
  const { depositedPositions, borrowedPositions, isLoaded } = useUserPositions();
  const { user, reserves } = useDynamicPoolDataContext();
  const [selectedA, setSelectedA] = [
    useToolsStore.use.selectedA(),
    useToolsStore.use.setSelectedA(),
  ];
  const [selectedB, setSelectedB] = [
    useToolsStore.use.selectedB(),
    useToolsStore.use.setSelectedB(),
  ];
  const [amountA, setAmountA] = [useToolsStore.use.amountA(), useToolsStore.use.setAmountA()];
  const [amountB, setAmountB] = [useToolsStore.use.amountB(), useToolsStore.use.setAmountB()];

  const depositReserves: ReserveWithBalance[] = useMemo(
    () => ToolsHelper.getReservesWithBalance({ reserves, positions: depositedPositions }),
    [depositedPositions, reserves]
  );

  const borrowReserves: ReserveWithBalance[] = useMemo(
    () =>
      ToolsHelper.getReservesWithBalance({
        reserves,
        positions: borrowedPositions,
        isBorrow: true,
      }),
    [borrowedPositions, reserves]
  );

  const handleWalletSelect = useCallback(
    (selectedA: ReserveWithBalance) => {
      if (selectedB?.symbol !== selectedA.symbol) {
        setSelectedA(selectedA);
      }
    },
    [selectedB]
  );

  const handleBorrowSelect = useCallback(
    (selectedB: ComputedReserveData) => {
      if (selectedB.symbol !== selectedA?.symbol) {
        setSelectedB(selectedB);
      }
    },
    [selectedA]
  );

  useEffect(() => {
    return () => {
      setSelectedA(null);
      setAmountA('');
      setSelectedB(null);
      setAmountB('');
    };
  }, []);

  return (
    <>
      <ScreenWrapper className="tools">
        <PageHeader text="Deleverage" />
        <p className="tools__subtitle">Available to deleverage</p>

        {user ? (
          <>
            <div className="tools__section">
              <BasicBox className="tools__section-inner">
                <BasicBox.Header className="tools__section-header">
                  <BasicBox.Title>Choose position to flash repay</BasicBox.Title>
                </BasicBox.Header>
                {isLoaded && borrowReserves.length > 0 ? (
                  <BasicBox.Body>
                    <BasicTable>
                      <BasicTable.Header className="tools__table-header">
                        <BasicTable.Item>Assets</BasicTable.Item>
                        <BasicTable.Item>borrows</BasicTable.Item>
                        <BasicTable.Item>Interest</BasicTable.Item>
                      </BasicTable.Header>

                      {borrowReserves.map((borrowReserve, index) => (
                        <ToolRow
                          key={index}
                          handleClick={() => handleWalletSelect(borrowReserve)}
                          isActive={borrowReserve.symbol === selectedA?.symbol}
                          isDisabled={borrowReserve.symbol === selectedB?.symbol}
                          symbol={borrowReserve.symbol}
                          amount={borrowReserve.balance}
                          price={borrowReserve.priceInMarketReferenceCurrency}
                          apy={borrowReserve.variableBorrowAPY}
                        />
                      ))}
                    </BasicTable>
                  </BasicBox.Body>
                ) : (
                  <BasicBox.Body>No position available</BasicBox.Body>
                )}

                <PreloaderOver isShown={!isLoaded} />
              </BasicBox>
              <BasicBox className="tools__section-inner">
                <BasicBox.Header className="tools__section-header">
                  <BasicBox.Title>Choose asset to repay with</BasicBox.Title>

                  <ButtonTabs
                    className="tools__table-tabs"
                    tabs={DELEVERAG_TABS}
                    selectedTab={-1}
                    setSelectedTab={() => {}}
                  />
                </BasicBox.Header>
                {isLoaded && depositReserves.length > 0 ? (
                  <BasicBox.Body>
                    <BasicTable className="tools__table tools__table--active">
                      <BasicTable.Header className="tools__table-header">
                        <BasicTable.Item>Assets</BasicTable.Item>
                        <BasicTable.Item className="tools__table-item">Deposits</BasicTable.Item>
                        <BasicTable.Item>APY</BasicTable.Item>
                      </BasicTable.Header>

                      {depositReserves.map((depositReserve, index) => (
                        <ToolRow
                          key={index}
                          handleClick={() => handleBorrowSelect(depositReserve)}
                          isActive={depositReserve.symbol === selectedB?.symbol}
                          isDisabled={depositReserve.symbol === selectedA?.symbol}
                          symbol={depositReserve.symbol}
                          amount={depositReserve.balance}
                          price={depositReserve.priceInMarketReferenceCurrency}
                          apy={depositReserve.supplyAPY}
                          isDeposit
                        />
                      ))}
                    </BasicTable>
                  </BasicBox.Body>
                ) : (
                  <BasicBox.Body>No asset available</BasicBox.Body>
                )}

                <PreloaderOver isShown={!isLoaded} />
              </BasicBox>
            </div>
            <BasicBox className="tools__summery">
              <BasicBox.Header>
                <BasicBox.Title>Summary</BasicBox.Title>
              </BasicBox.Header>
              <BasicBox.Body className="tools__summery-body">
                <>
                  <DeleverageForm
                    selectedA={selectedA as ReserveWithBalance}
                    selectedB={selectedB as ReserveWithBalance}
                    user={user}
                  />
                  <ToolsInfo>
                    <ToolsInfo.Group>
                      <ToolsInfo.Position
                        item={selectedA}
                        amount={valueToBigNumber(amountA)}
                        title="Total repaying"
                      />
                      <ToolsInfo.Position
                        item={selectedB}
                        amount={valueToBigNumber(amountB)}
                        title="Repaying with"
                      />
                    </ToolsInfo.Group>
                  </ToolsInfo>
                </>
              </BasicBox.Body>
            </BasicBox>
          </>
        ) : (
          <NoDataPanel
            title="Please connect your wallet"
            withConnectButton
            className="tools__connect"
          />
        )}
      </ScreenWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}
