export const mainet = {
  LendingPoolAddressesProvider: '0x011C0D38Da64b431A1BdfC17aD72678EAbF7f1FB',
  LendingPoolAddressesProviderRegistry: '0xaC538416BA7438c773F29cF58afdc542fDcABEd4',
  ReserveLogic: '0x4BE75900F8a54D7D5962e950F3AF98d61f8FBDd9',
  GenericLogic: '0xaEde01960810a655AEcf86278Bfdf5C968198B89',
  ValidationLogic: '0x3c0ADA81038A078cc1272aC22745ddD1Ab8839Af',
  LendingPoolImpl: '0x05bfA9157E92690b179033cA2f6dd1e86B25Ea4D',
  LendingPool: '0x2409aF0251DCB89EE3Dee572629291f9B087c668',
  LendingPoolConfiguratorImpl: '0x40daf7BE3a99b898f54fB7968D16Db5103835347',
  LendingPoolConfigurator: '0x408c9764993209DC772eB12FF641F4b55F5b005C',
  StableAndVariableTokensHelper: '0xbcdAd8DEDfF0BDf425bbF7cb5970157dA492279a',
  ATokensAndRatesHelper: '0xAaB1B4260297FfFdbc267A46B73C7Fcd43b95645',
  AToken: '0x12df06aef0c0D5aC1809B73cEb15Da4eA651bc52',
  StableDebtToken: '0xc7c3D79D61C9e4E27Ff904f66386A89726a60a3C',
  VariableDebtToken: '0xa71b2cEbb5E0aC07497F825470E45383e1997083',
  AaveOracle: '0xAC4A2aC76D639E10f2C05a41274c1aF85B772598',
  LendingRateOracle: '0x413A1f0098A8C5Ba1078552Af515bd0146522fE4',
  AaveProtocolDataProvider: '0x17938eDE656Ca1901807abf43a6B1D138D8Cd521',
  WETHGateway: '0x2fd22a9Db5e07Bfff9145E549c463967864D7d2f',
  DefaultReserveInterestRateStrategy: '0xBA285eD9a1fc13b4860e2d2cB8910BCd1bE9E6a6',
  rateStrategyStableOne: '0xD4fB946E72C4B9AB8BC1728dF2012f7057DA1479',
  rateStrategyWETH: '0x07b12795E18CB1568E9C24df4a54356846001ab7',
  rateStrategyVolatileOne: '0xBA285eD9a1fc13b4860e2d2cB8910BCd1bE9E6a6',
  LendingPoolCollateralManagerImpl: '0x2e9F846ce3820531B52c08d3d4543bE5C8FE7ddb',
  LendingPoolCollateralManager: '0x2e9F846ce3820531B52c08d3d4543bE5C8FE7ddb',
  WalletBalanceProvider: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
  UiPoolDataProvider: '0x19dd01d5cd88eaE5674A2AB94d654F9413E20ce5',
  assets: [
    '0x6b175474e89094c44da98b954eedeac495271d0f',
    '0x853d955acef822db058eb8505911ed77f175b99e',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    '0x29127fe04ffa4c32acac0ffe17280abd74eac313',
    '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
    '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
    '0x26fa3fffb6efe8c1e69103acb4044c26b9a106a9',
    '0xd533a949740bb3306d119cc777fa900ba034cd52',
    '0x3b79a28264fc52c7b4cea90558aa0b162f7faf57',
    '0x5938999Dd0cC4d480c3B1a451AECc78aE4dDaab5',
    '0xB9D7DdDca9a4AC480991865EfEf82E01273F79C3',
    '0x9b06F3C5de42D4623D7A2Bd940EC735103c68A76',
  ],
  tokens: [
    '0xb95BD0793bCC5524AF358ffaae3e38c3903C7626',
    '0x8C240C385305aeb2d5CeB60425AABcb3488fa93d',
    '0x67fadbD9Bf8899d7C578db22D7af5e2E500E13e5',
    '0x6Ace5c946a3Abd8241f31f182c479e67A4d8Fc8d',
    // '0x02738ef3f8d8D3161DBBEDbda25574154c560dAe',
    '0xC480a11A524E4DB27c6d4E814b4D9B3646bC12Fc',
    '0xaDFa5Fa0c51d11B54C8a0B6a15F47987BD500086',
    '0x243387a7036bfcB09f9bF4EcEd1E60765D31aA70',
    '0xdb1A8f07f6964EFcFfF1Aa8025b8ce192Ba59Eba',
    '0xC4BF704f51aa4ce1AA946FfE15646f9B271ba0fa',
    '0x1254B1fd988A1168E44a4588bb503a867F8E410F',
    '0x51e0F19bF0B765Bc55724C7374FE00aB229427d9',
    '0xBac9D17f290260a1D5f1b69cAC84dBa6b4488d66',
    '0x64E4843FfdFB62d205B049ddbe8B949534e4e2D4',
    '0x5C8cb0e43CB17553ab4A37011C3DC743aEB3f241',
    '0xd5Bfd3d736477F48eFc873ee464F4a8B5447850b',
    '0x9ABe34021128C17DE3C2180A02932Eb5e1bb18ef',
    '0x29d567fA37B4aF64DD1b886571CD1fF5d403AC3F',
    '0xB9e8bcd56F26B0540989a66Aa24D431Cdb0AFfA0',
    '0x13CdfDD18e6BB8D41bE0A55D9Cf697C0EF11176b',
    '0xEc12f63116bD2493104a26FbDBCd70f51ab7B2C1',
  ],
  _OLDToken: '0x55C08ca52497e2f1534B59E2917BF524D4765257',
  _OLDETHLP: '0x3E04863DBa602713Bb5d0edbf7DB7C3A9A2B6027',
  MultiFeeDistribution: '0x7c0bF1108935e7105E218BBB4f670E5942c5e237',
  StakingRewards: '0xe3643512532Fe1f3522745787e883F9729527186',
  ChefIncentivesController: '0x21953192664867e19F85E96E1D1Dd79dc31cCcdB',
  Leverager: '0x780dcFdA4A6DE88D5C4F912345B99223012F32eD',
  WalletBalanceProvider1: '0x7935330D82c3B2b96B2e6Bdec13e2dFCA1C6addd',
  YoSifuStargateVault: '0x52EcDBCdca159e020597F6Eb7FB90A3E715EA3C1',
  YoSifuStargateVaultWrapper: '0x9E0C768D3A18c8cb7715257C9d3AafF3E6A03993',
  LPStaking: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
  V2: {
    ChefIncentivesController: '0xDB5C23ae97f76dacC907f5F13bDa54131C8e9e5a',
    MultiFeeDistribution: '0x0a7B2A21027F92243C5e5E777aa30BB7969b0188',
  },
  V3: {
    ChefIncentivesController: '0x8c7F54a2FB90C9366E3fA8b31aF47c0dD357AEDf',
    MultiFeeDistribution: '0x4c7e22c7a1FD8Aa06C23237F56d1B974853115C7',
    NonfungiblePositionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
  },
  NFTCollections: [
    {
      name: 'Interport Explorer (ITPe)',
      token: '0x230cD36784120726A5F4d815f25b92Bac8BE3623',
    },
    {
      name: 'LOOP Kitty (METK)',
      token: '0x3E063f2E3354265FE80EAD6cA375Ab73FD0029a2',
    },
  ],
  NFTBuyer: '0x42bBFEac6E5b6333A04d978CC6CA73A350fc3fda',
};

export const iota = {
  LendingPoolAddressesProviderRegistry: '0xA9Bb7ebb4F51B0e0BbD0FaE640e32298a0Bcf4A5',
  LendingPoolAddressesProvider: '0x3893e30500d13990b36C9b2A05FE210A204a2F9a',
  ReserveLogic: '0xF0e279c650173861FcD3cFa0c958acD39D8807C7',
  GenericLogic: '0xaE1f0C461e3af698f5E0560c0891a44e1bfeb486',
  ValidationLogic: '0x01A1a1f20da7bDD811E6b14fDE1b2eA668552aA5',
  LendingPoolImpl: '0x37Dbfc755B6fdb71FBeD8b95D2871E3daAA2Ae0A',
  LendingPool: '0xb51d18e6D14beD6C3af796201A6f2DcEbBE7BfEE',
  LendingPoolConfiguratorImpl: '0xfbd6C884b045C3Dea784798F0BfD3678639Ae179',
  LendingPoolConfigurator: '0x7C7b6466e04c4e6dEc9bF1C3c4eC48fa499c8CF2',
  StableAndVariableTokensHelper: '0xD3fE536536180105ACeACe52014EA0Ba82135774',
  ATokensAndRatesHelper: '0x240Cb36b29FF7Dd3ADe14bfca3Df9BAb4E1FE7C2',
  AToken: '0x6F0227BE988a6fC2D432Cd3b0B44373152098DEd',
  StableDebtToken: '0xd6eCa78392C1889253EdeE0268caD2D835A2b683',
  VariableDebtToken: '0x48801FCC6Ae38b5c68F09b8cB66129aB2751584C',
  FallbackOracle: '0x0113909ea88479816cB8a53f2cc28cAcd1a62441',
  AaveOracle: '0xABca1cCA807c85Fe19354c1ed1D8a2130Ac476D3',
  LendingRateOracle: '0x7628A4109a63f23667C5365d9013c8C2162F61a6',
  AaveProtocolDataProvider: '0x779a294CF4D200936881c4c8d0771b8a1935fB5B',
  PriceProvider: '0x427E373B0882E534ddcC71C30dd8cc83aF83b568',
  PriceProviderImpl: '0x0dac7012f91E847A69d3C236E9c6D1A2518b9838',
  MultiFeeDistribution: '0x92c69EF18B4735cB9EFC8ec6383A93a659DA749F',
  MultiFeeDistributionImpl: '0x6aDAcB4EE4e1532229f7c6fB14E666c7aaD9BCBa',
  MiddleFeeDistribution: '0xe6dF9EAFEc25BD39BC1Ec146B044C8C7deE1658D',
  MiddleFeeDistributionImpl: '0x47ae87e83cd70382861B153F99051A6612F5F9a8',
  EligibilityDataProvider: '0x2a9DdF6883FB77FD7A26361Be53252CF5c2928ad',
  EligibilityDataProviderImpl: '0x383F1540607F707b15FBA573c263252ED53A0a3f',
  ChefIncentivesController: '0xE7a2ad04066D1c634BCA379fad32832D3B1475f1',
  ChefIncentivesControllerImpl: '0xD29889eF013811DD7c8dE98dFCc4c3a5369878d8',
  LockZap: '0xCB1a563F3b8D2665FcE240d98C152eFE8daD8310',
  LockZapImpl: '0x30d098b3ac8305CFa365e9B60e8F507Bd8e607CF',
  DefaultReserveInterestRateStrategy: '0x589f0aF4A3e5D7448fA045234b45deC136668F4d',
  LendingPoolCollateralManager: '0xa305942BE411c00C3eE0fc866444A9F27325D9D2',
  WalletBalanceProvider: '0x3cDB9118EA9197c94600a06748fDC78B29dd14D9',
  UiPoolDataProvider: '0x551B96EfA83e1E14aD9BA1b7934C736276C8c3E7',
  Looping: '0x77D6b18163BbBf24ef5701b01fD0c450830f5498',
  LoopingImpl: '0x868f49611f1161e1034df482A237F8041a7De2eA',
  FlashLoanBorrow: '0xC28D0b7E1182491B3a741697eD54B74440287af3',
  FlashLoanRepay: '0x0494B9c7D97a98E1EC39849254e42AcF776aB466',

  LeveragerImpl: '0x82c5E5076ac12b05114a2ca86a1Bf656080f1704',
  SwapLoopWlpLeverager: '0xE264A6EaaA13C79E7b01bF3C7Ad6811C96c96796',
  SwapLoopWlpLeveragerImpl: '0x101AA07900AcDC0def8dd503d91E387Fbca156d5',
  SwapLoopLeverager: '0x149411e3Ae8f4A669B589f9fF7d8043Ba777a16b',
  SwapLoopLeveragerImpl: '0x32E8110e7D330569225d4430219e26943F12Cbed',
  assets: [
    '0x46A49D74C3230586eb9122c1bD71224888A0f98a',
    '0x4fDF3c8CA65CFE2cE1244fC5cB241c20625A37d7',
    '0x85bAD360e324FE1234337d881cd0FDCcf163D9c9',
    '0x23443bBC9edAe7528b732E17e5B841C261A30ACA',
    '0x59E9ed98916a2bE09A01afFF93f8534d2Fb51D64',
  ],
  tokens: ['0xF5755e48B6F2F06F8ea904bdA26177CB3ca06Ff0'],
  _OLDToken: '0x55C08ca52497e2f1534B59E2917BF524D4765257',
  _OLDETHLP: '0x3E04863DBa602713Bb5d0edbf7DB7C3A9A2B6027',
};
