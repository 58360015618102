import { LockDurationIndex } from '../../../../modules/manage/components/ManageLock/ManageLock';
import { DateUtils } from '../../../../utils';
import staticStyles from './style';
import { BasicBox } from '../../../BasicBox/BasicBox';
import classNames from 'classnames';
import { MathUtils } from '../../../../utils/math.utils';
import { useCallback, useMemo } from 'react';
import { useProviderContext } from '../../../../libs/provider/WalletProvider';
import { MultiFeeDistributionService } from '../../../../libs/aave-protocol-js/MulteFeeDistributionContract';
import { REWARD_ADDRESS, MULTI_FEE_DISTRIBUTION_ADDRESS } from '../../../../shared';
import { Address } from 'viem';
import { sendEthTransaction } from '../../../../helpers/send-ethereum-tx';
import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import { useLockData } from '../../../../hooks';
import { ZapFromEnum } from '../../ZapModal';

export function ZapLockAprs({ selectedTabFrom }: { selectedTabFrom: ZapFromEnum }) {
  const { provider } = useProviderContext();
  const { user } = useDynamicPoolDataContext();
  const { lockDurations, lockMultipliers, lockApr, lockIndex, setLockIndex } = useLockData();

  const multiFeeDistributionService = useMemo(
    () => new MultiFeeDistributionService(provider, REWARD_ADDRESS, MULTI_FEE_DISTRIBUTION_ADDRESS),
    [provider]
  );

  const handleLockDurationChange = useCallback(
    async (index: LockDurationIndex) => {
      if (user) {
        const actionTx = await multiFeeDistributionService.setDefaultRelockTypeIndex(
          index,
          user.id as Address
        );

        setLockIndex(index);

        await sendEthTransaction(actionTx, provider, () => {}, null, {
          onError: () => {
            setLockIndex(lockIndex);
          },
        });
      }
    },
    [provider, user]
  );

  return (
    <div className="zap-apr">
      <p className="zap-apr__title">Please select your lock duration</p>

      {
        <ul className="zap-apr__list">
          {lockDurations
            .map((duration, index) => {
              const formatetDuration = DateUtils.humanizedTime(Number(duration));

              if (index === 0 && selectedTabFrom === ZapFromEnum.Vesting) {
                return null;
              }

              return (
                <BasicBox
                  variant="secondary"
                  className={classNames(
                    'zap-apr__item',
                    index === lockIndex && 'zap-apr__item--active'
                  )}
                  key={index}
                  onClick={() => handleLockDurationChange(index as LockDurationIndex)}
                >
                  <p className="zap-apr__item-time">{formatetDuration}</p>
                  <span className="zap-apr__item-apr">
                    {MathUtils.truncateNumber(lockApr.times(lockMultipliers[index]), 2)} %
                  </span>
                </BasicBox>
              );
            })
            .reverse()}
        </ul>
      }

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
